import * as types from './actionTypes';

export const addInsurance = (data) => {
  return {
    type: types.ADD_INSURANCE,
    payload: data,
  };
};

export const addInsuranceSuccess = (data) => {
  return {
    type: types.ADD_INSURANCE_SUCCESS,
    data,
  };
};

export const addInsuranceError = (data) => {
  return {
    type: types.ADD_INSURANCE_ERROR,
    data,
  };
};

export const getAllInsurance = (data) => {
  return {
    type: types.GET_ALL_INSURANCE,
    data,
  };
};

export const setAllInsurance = (data) => {
  return {
    type: types.SET_ALL_INSURANCE,
    data,
  };
};

export const searchInsurance = (data) => {
  return {
    type: types.SEARCH_INSURANCE,
    payload: data,
  };
};

export const deleteInsurance = (data) => {
  return {
    type: types.DELETE_INSURANCE,
    payload: data,
  };
};

export const deleteInsuranceSuccess = (data) => {
  return {
    type: types.DELETE_INSURANCE_SUCCESS,
    payload: data,
  };
};

export const deleteInsuranceError = (data) => {
  return {
    type: types.DELETE_INSURANCE_ERROR,
    payload: data,
  };
};
export const updateInsurance = (data) => {
  return {
    type: types.UPDATE_INSURANCE,
    payload: data,
  };
};

export const updateInsuranceSuccess = (data) => {
  return {
    type: types.UPDATE_INSURANCE_SUCCESS,
    payload: data,
  };
};

export const updateInsuranceError = (data) => {
  return {
    type: types.UPDATE_INSURANCE_ERROR,
    payload: data,
  };
};

export const resetUpdateInsurance = (data) => {
  return {
    type: types.UPDATE_INSURANCE_RESET,
    payload: data,
  };
};

export const resetCreateInsurance = (data) => {
  return {
    type: types.ADD_INSURANCE_RESET,
    payload: data,
  };
};

export const activeInsuranceStatus = (data) => {
  return {
    type: types.ACTIVE_INSURANCE_STATUS,
    payload: data,
  };
};

export const activeInsuranceStatusSuccess = (data) => {
  return {
    type: types.ACTIVE_INSURANCE_STATUS_SUCCESS,
    payload: data,
  };
};

export const activeInsuranceStatusError = (data) => {
  return {
    type: types.ACTIVE_INSURANCE_STATUS_ERROR,
    payload: data,
  };
};

export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const getNextPageInsurance = (data) => {
  return {
    type: types.NEXT_PAGE_INSURANCE,
    payload: data,
  };
};
