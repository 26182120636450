import * as types from './actionTypes';

export const addEmployee = (data) => {
  return {
    type: types.ADD_EMPLOYEE,
    payload: data,
  };
};

export const addEmployeeSuccess = (data) => {
  return {
    type: types.ADD_EMPLOYEE_SUCCESS,
    data,
  };
};

export const addEmployeeError = (data) => {
  return {
    type: types.ADD_EMPLOYEE_ERROR,
    data,
  };
};

export const getAllEmployee = (data) => {
  return {
    type: types.GET_ALL_EMPLOYEE,
    data,
  };
};

export const setAllEmployee = (data) => {
  return {
    type: types.SET_ALL_EMPLOYEE,
    data,
  };
};

export const searchEmployee = (data) => {
  return {
    type: types.SEARCH_EMPLOYEE,
    payload: data,
  };
};

export const deleteEmployee = (data) => {
  return {
    type: types.DELETE_EMPLOYEE,
    payload: data,
  };
};

export const deleteEmployeeSuccess = (data) => {
  return {
    type: types.DELETE_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const deleteEmployeeError = (data) => {
  return {
    type: types.DELETE_EMPLOYEE_ERROR,
    payload: data,
  };
};
export const updateEmployee = (data) => {
  return {
    type: types.UPDATE_EMPLOYEE,
    payload: data,
  };
};

export const updateEmployeeSuccess = (data) => {
  return {
    type: types.UPDATE_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const updateEmployeeError = (data) => {
  return {
    type: types.UPDATE_EMPLOYEE_ERROR,
    payload: data,
  };
};

export const resetUpdateEmployee = (data) => {
  return {
    type: types.UPDATE_EMPLOYEE_RESET,
    payload: data,
  };
};

export const resetCreateEmployee = (data) => {
  return {
    type: types.ADD_EMPLOYEE_RESET,
    payload: data,
  };
};

export const activeEmployeeStatus = (data) => {
  return {
    type: types.ACTIVE_EMPLOYEE_STATUS,
    payload: data,
  };
};

export const activeEmployeeStatusSuccess = (data) => {
  return {
    type: types.ACTIVE_EMPLOYEE_STATUS_SUCCESS,
    payload: data,
  };
};

export const activeEmployeeStatusError = (data) => {
  return {
    type: types.ACTIVE_EMPLOYEE_STATUS_ERROR,
    payload: data,
  };
};

export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const getNextPageEmployee = (data) => {
  return {
    type: types.NEXT_PAGE_EMPLOYEE,
    payload: data,
  };
};

export const getEmployeePermissions = (data) => {
  return {
    type: types.GET_EMPLOYEE_PERMISSIONS,
    payload: data,
  };
};

export const setEmployeePermissions = (data) => {
  return {
    type: types.SET_EMPLOYEE_PERMISSIONS,
    payload: data,
  };
};
export const resetPassword = (data) => {
  return {
    type: types.RESET_PASSWORD,
    payload: data,
  };
};
export const resetPasswordSuccess = (data) => {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};
export const resetPasswordError = (data) => {
  return {
    type: types.RESET_PASSWORD_ERROR,
    payload: data,
  };
};

export const getEmployeePolicyList = () => {
  return {
    type: types.GET_EMPLOYEE_POLICY_LIST,
  };
};
export const setEmployeePolicyData = (data) => {
  return {
    type: types.SET_EMPLOYEE_POLICY_DATA,
    payload: data,
  };
};

export const getEmployeePolicyDetail = (data) => {
  return {
    type: types.GET_POLICY_DETAIL_DATA,
    payload: data,
  };
};

export const setEmployeePolicyDetail = (data) => {
  return {
    type: types.SET_POLICY_DETAIL_DATA,
    payload: data,
  };
};

export const getEmployeeProfile = () => {
  return {
    type: types.GET_EMPLOYEE_PROFILE,
  };
};

export const setEmployeeProfile = (data) => {
  return {
    type: types.SET_EMPLOYEE_PROFILE,
    payload: data,
  };
};

export const updateEmployeeProfile = (data) => {
  return {
    type: types.UPDATE_EMPLOYEE_PROFILE,
    payload: data,
  };
};

export const setUpdateEmployeeProfileSuccess = (data) => {
  return {
    type: types.SET_UPDATE_EMPLOYEE_PROFILE_SUCCESS,
    payload: data,
  };
};
export const registerEmployeeAssessment = (data) => {
  return {
    type: types.REGISTER_EMPLOYEE_ASSESSMENT,
    payload: data,
  };
};

export const setRegisterEmployeeAssessmentSuccess = (data) => {
  return {
    type: types.SET_REGISTER_EMPLOYEE_ASSESSMENT_SUCCESS,
    payload: data,
  };
};

export const setMetaParamsError = (data) => {
  return {
    type: types.SET_META_PARAMS_ERROR,
    payload: data,
  };
};

export const updateMobileNumber = (data) => {
  return {
    type: types.UPDATE_MOBILE_NUMBER,
    payload: data,
  };
};

export const setUpdateMobileSuccess = (data) => {
  return {
    type: types.SET_UPDATE_MOBILE_SUCCESS,
    payload: data,
  };
};
