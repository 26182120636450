import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPassword = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const ResetPassword = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const MobileVerification = lazy(() => import('../container/profile/authentication/overview/MobileVerification'));
const OTPVerification = lazy(() => import('../container/profile/authentication/overview/OTPVerification'));
const CreateMpin = lazy(() => import('../container/profile/authentication/overview/CreateMpin'));
const MobileLogin = lazy(() => import('../container/profile/authentication/overview/MobileLogin'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route path="register" element={<SignUp />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="*" element={<AuthRoot />} />
      <Route path="employee-verification" element={<MobileVerification />} />
      <Route path="otp-verification" element={<OTPVerification />} />
      <Route path="create-mpin" element={<CreateMpin />} />
      <Route path="employee-login" element={<MobileLogin />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
