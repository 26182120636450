/* eslint-disable no-case-declarations */
import * as types from './actionTypes';

const initState = {
  loading: false,
  allClaims: [],
  allStatus: [],
  metaParams: {},
  errors: {},
  employee: [],
  claimType: '',
  instruction: [],
  patient: [],
  claimsDocuments: [],
  updateStatus: false,
  SuccessStatus: false,
  InstructionStatus: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const claimReducer = (state = initState, action) => {
  const { type, data, payload } = action;

  switch (type) {
    case types.GET_ALL_CLAIMS:
      return {
        ...state,
        loading: true,
      };
    case types.SET_ALL_CLAIMS:
      return {
        ...state,
        loading: false,
        allClaims: data,
      };
    case types.SET_STATUS:
      return {
        ...state,
        loading: false,
        allStatus: action.payload,
      };
    case types.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateStatus: action.payload,
      };
    case types.GET_EMPLOYEE:
      return {
        ...state,
        loading: true,
      };
    case types.SET_EMPLOYEE:
      return {
        ...state,
        loading: false,
        employee: payload,
      };
    case types.GET_PATIENT:
      return {
        ...state,
        loading: true,
      };
    case types.SET_PATIENT:
      return {
        ...state,
        loading: false,
        patient: payload,
      };
    case types.SET_CLAIM_TYPE:
      return {
        ...state,
        loading: false,
        claimType: payload,
      };
    case types.GET_INSTRUCTIONS:
      return {
        ...state,
        loading: true,
      };
    case types.SET_INSTRUCTIONS:
      return {
        ...state,
        loading: false,
        instruction: payload,
      };
    case types.RAISE_CLAIM:
      return {
        ...state,
        loading: true,
      };
    case types.RAISE_CASHLESS_CLAIM:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_CLAIM_ERROR:
      return {
        ...state,
        loading: false,
        errors: data?.formErrors,
      };
    case types.RAISE_CLAIM_SUCCESS:
      let addNewParams = {};
      if (state.metaParams.pagination.total_count) {
        addNewParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count + 1 };
      }
      return {
        ...state,
        loading: false,
        allClaims: [data.data, ...state.allClaims],
        metaParams: { pagination: { ...addNewParams } },
      };

    case types.RAISE_CLAIM_SUCCESS_SECOND:
      return {
        ...state,
        loading: false,
        SuccessStatus: action.payload,
        // allClaims: [payload],
        // allClaims: [payload.data, ...state.allClaims.list],
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.UPDATE_INSTUCTIONS:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_INSTUCTIONS_SUCCESS:
      return {
        ...state,
        InstructionStatus: action.payload,
      };
    case types.SET_CLAIMS_DOCUMENTS:
      return {
        ...state,
        claimsDocuments: action.payload,
      };
    case types.UPLOAD_CLAIMS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadClaimsDocuments: action.payload,
      };

    default:
      return state;
  }
};
export default claimReducer;
