/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';

export const addCdApi = (values) => {
  const formData = new FormData();
  formData.append('type', values.type);
  formData.append('amount', values.amount);
  formData.append('invoice', values.invoice);
  formData.append('organisationId', values.organisationId);

  return DataService.post(`cd-statement/addattachment`, formData);
};

// export const updateEndorApi = (values) => {
//   const formData = new FormData();
//   formData.append('amount', values.amount);
//   formData.append('invoice', values.invoice);
//   formData.append('invoice1', values.invoice);
//   formData.append('endId', values.endId);
//   formData.append('status', 1); // Set status statically to 1

//   return DataService.put(`endorsement/approved/${values.endId}`, formData);
// };

export const updateEndorApi = (values) => {
  return DataService.put(`endorsement/approved/${values.endId}`, {
    status: 1,
    amount: values.amount,
    invoice: values.invoice,
    invoice1: values.invoice1,
    endId: values.endId,
    employee_code: values.employee_code,
  });
};
// export const updateEndorApi = (values) => {
//   const formData = new FormData();
//   formData.append('amount', values.amount);
//   formData.append('invoice', values.invoice);
//   formData.append('invoice1', values.invoice1);
//   formData.append('endId', values.endId);
//   // formData.append('status', values.status);
//   formData.append('status', '1'); // Set status statically to 1

//   // Simplified without redundant `await`
//   return fetch(`endorsement/approved/${values.endId}`, {
//     method: 'PUT',
//     body: formData,
//   }).then((response) => response.json());
// };

// export const updateEndorApi = (values) => {
//   const formData = new FormData();
//   formData.append('amount', values.amount);
//   formData.append('invoice', values.invoice);
//   formData.append('invoice1', values.invoice1);
//   formData.append('endId', values.endId);
//   // formData.append('status', values.status);
//   formData.append('status', 1); // Set status statically to 1

//   // Simplified without redundant `await`
//   return fetch(`endorsement/approved/${values.endId}`, {
//     method: 'PUT',
//     body: formData,
//   }).then((response) => response.json());
// };

export const updateCdApi = (values) => {
  const formData = new FormData();
  formData.append('cdStatementId', values.cdStatementId);
  formData.append('amount', values.amount);
  formData.append('invoice', values.invoice);
  formData.append('invoice1', values.invoice1);
  formData.append('_method', 'PUT');

  return DataService.post(`cd-statement/${values.cdStatementId}`, formData);
};

export const getAllCdApi = (value) => {
  return DataService.get(`cd-statement?organisationId=${value.id}&page=${value.page}`);
};
export const getExportCdApi = (value) => {
  return DataService.get(`cd-statement/export?organisationId=${value}`);
};
export const addCdAtachmentApi = (values) => {
  const formData = new FormData();
  formData.append('cdStatementId', values.cdStatementId);
  formData.append('amount', values.amount);
  formData.append('invoice', values.invoice);
  formData.append('invoice1', values.invoice1);
  formData.append('_method', 'PUT');
  return DataService.post(`cd-statement/addattachment/${values.cdStatementId}`, formData);
};
