/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';

export const getAllClaimsApi = (data) => {
  return DataService.get(
    `/claims?search=${data.search}&page=${data.page}&claimType=${data.ClaimType}&policyId=${data.policyId}&orgId=${data.orgId}&status=${data.status}`,
  );
};

export const getExportApi = (data) => {
  return DataService.get(`/claim/export/csv?claimType=${data.claimType}&policyId=${data.policyId}&orgId=${data.orgId}`);
};
export const getAllStatusApi = (data) => {
  return DataService.get(`claim/all/status?claimType=${data.claimType}&claimStatus=${data.claimStatus}`);
};

export const updateStatusApi = (data) => {
  const formData = new FormData();
  formData.append('statusDocument', data.statusDocument);
  formData.append('status', data.status);
  return DataService.post(`claim/${data.id}/status/update`, formData);
};
export const getEmployeeApi = (data) => {
  return DataService.get(`claim/self/member/list?policyId=${data.policyNumber}&orgId=${data.orgId}`);
};
export const getEmployeeSuperAdminApi = (data) => {
  return DataService.get(`claim/self/list?policyId=${data}`);
};
export const getInstructionApi = (data) => {
  const payload = data === 'all' ? '' : data;
  return DataService.get(`claim/instruction/get?claimType=${payload}`);
};
export const getPatientApi = (data) => {
  return DataService.get(`claim/${data?.selfRelationId}/${data?.policyId}/member/list`);
};
export const raiseClaimApi = (data) => {
  const formData = new FormData();
  formData.append('claimDocuments[]', data.claimDocuments);
  formData.append('member_id', data.member_id);
  formData.append('hospitalName', data.hospitalName);
  formData.append('email', data.email);
  formData.append('hospitalZipcode', data.hospitalZipcode);
  formData.append('intimate', data.intimate.toString());
  formData.append('dateOfAdmission', data.dateOfAdmission);
  return DataService.post(`claim/${data.id}/${data.type}/raise`, formData);
};

export const raiseCashlessClaimApi = (data) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('mobile', data.mobile);
  formData.append('uhid', data.uhid);
  formData.append('type', data.type);
  formData.append('claim_manager_id', data.claim_manager_id);
  formData.append('claim_number', data.claim_number);
  return DataService.post(`cashless/claim/${data.id}/${data.type}/raise`, formData);
};

export const updateInstructionsApi = (data) => {
  return DataService.post(`claim/term/condition/edit`, data);
};

export const getClaimsDocumentsApi = (id) => {
  return DataService.get(`claim/document/download?claimId=${id}`);
};
export const uploadClaimsDocumentsApi = (data) => {
  const formData = new FormData();
  formData.append('claimDocuments[]', data.fileData);
  return DataService.post(`claim/${data.id}/document/upload`, formData);
};

export const claimZipFileApi = (data) => {
  return DataService.post(`claim/document/zip-files`, data);
};
