/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../insurance/actionTypes';
import {
  addInsuranceApi,
  deleteInsuranceApi,
  getAllInsuranceApi,
  getNextPageInsuranceApi,
  insuranceActiveApi,
  searchInsuranceApi,
  updateInsuranceApi,
} from '../requests/insuranceRequests';
import {
  activeInsuranceStatusError,
  activeInsuranceStatusSuccess,
  addInsuranceError,
  addInsuranceSuccess,
  deleteInsuranceError,
  deleteInsuranceSuccess,
  setAllInsurance,
  setMetaParams,
  updateInsuranceError,
  updateInsuranceSuccess,
} from '../../insurance/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';

function* addInsuranceHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addInsuranceApi, values?.payload);
    if (data?.status) {
      yield put(addInsuranceSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (data.meta_params.formErrors.length) {
        data.meta_params.formErrors.map((message) => {
          return toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      } else {
        yield toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      yield put(addInsuranceError(data.meta_params));
    }
  } catch (err) {
    yield put(addInsuranceError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getInsuranceHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllInsuranceApi, {});
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllInsurance(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addInsuranceError(data.message));
    }
  } catch (err) {
    yield put(addInsuranceError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* searchInsuranceHandler(values) {
  yield delay(500);
  yield startLoader('transparent');
  try {
    const { data } = yield call(searchInsuranceApi, values?.payload);
    if (data?.status) {
      yield put(setAllInsurance(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addInsuranceError(data.message));
    }
  } catch (err) {
    yield put(addInsuranceError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* deleteInsuranceHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(deleteInsuranceApi, values?.payload);
    if (data?.status) {
      yield put(deleteInsuranceSuccess(values.payload));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(deleteInsuranceError(data.message));
    }
  } catch (err) {
    yield put(deleteInsuranceError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateInsuranceHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(updateInsuranceApi, values?.payload);
    if (data?.status) {
      yield put(updateInsuranceSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (data.meta_params.formErrors.length) {
        data.meta_params.formErrors.map((message) => {
          return toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      } else {
        yield toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      yield put(updateInsuranceError(data.meta_params));
    }
  } catch (err) {
    yield put(updateInsuranceError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* activeInactiveHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(insuranceActiveApi, {
      id: values?.payload?.id,
      status: values?.payload?.status === 'inactive' ? 0 : 1,
    });
    if (data?.status) {
      yield put(activeInsuranceStatusSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(activeInsuranceStatusError(data.message));
    }
  } catch (err) {
    yield put(activeInsuranceStatusError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getNextInsuranceHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getNextPageInsuranceApi, value?.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllInsurance(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addInsuranceError(data.message));
    }
  } catch (err) {
    yield put(addInsuranceError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* insuranceSaga() {
  yield takeLatest(types.ADD_INSURANCE, addInsuranceHandler);
  yield takeLatest(types.GET_ALL_INSURANCE, getInsuranceHandler);
  yield takeLatest(types.SEARCH_INSURANCE, searchInsuranceHandler);
  yield takeLatest(types.DELETE_INSURANCE, deleteInsuranceHandler);
  yield takeLatest(types.UPDATE_INSURANCE, updateInsuranceHandler);
  yield takeLatest(types.ACTIVE_INSURANCE_STATUS, activeInactiveHandler);
  yield takeLatest(types.NEXT_PAGE_INSURANCE, getNextInsuranceHandler);
}
