import * as types from './actionTypes';

const initState = {
  loading: false,
  allHR: [],
  created: false,
  updated: false,
  metaParams: {},
  permissions: [],
  errors: {},
};

let remainingData = '';
let newParams = {};
const hrReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.ADD_HR:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_HR_SUCCESS:
      return {
        ...state,
        loading: false,
        created: true,
        allHR: [action?.payload?.data, ...state.allHR],
      };
    case types.ADD_HR_ERROR:
      return {
        ...state,
        loading: false,
        created: false,
        errors: data?.formErrors ?? {},
      };
    case types.UPDATE_HR_SUCCESS: {
      let updatedData = '';
      if (action.payload) {
        updatedData = state.allHR.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allHR: [...updatedData],
        updated: true,
      };
    }
    case types.GET_ALL_HR:
      return {
        ...state,
        loading: false,
      };
    case types.SET_ALL_HR:
      return {
        ...state,
        loading: false,
        allHR: action.payload,
      };
    case types.RESET_PASSWORD_HR:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_HR:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_HR_SUCCESS:
      if (action.payload) {
        remainingData = state.allHR.filter((item) => item.id !== action.payload);
        if (state.metaParams.pagination.total_count) {
          newParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count - 1 };
        }
      }
      return {
        ...state,
        allHR: [...remainingData],
        loading: false,
        metaParams: { pagination: { ...newParams } },
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.SET_HR_PERMISSION_SUCCESS:
      return {
        ...state,
        created: false,
        updated: false,
        loading: false,
        permissions: action.payload,
      };
    default:
      return state;
  }
};
export default hrReducer;
