import * as types from './actionTypes';

export const addHR = (data) => {
  return {
    type: types.ADD_HR,
    payload: data,
  };
};

export const addHRError = (data) => {
  return {
    type: types.ADD_HR_ERROR,
    data,
  };
};

export const addHRSuccess = (data) => {
  return {
    type: types.ADD_HR_SUCCESS,
    payload: data,
  };
};

export const getAllHR = (data) => {
  return {
    type: types.GET_ALL_HR,
    payload: data,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};
export const setAllHR = (data) => {
  return {
    type: types.SET_ALL_HR,
    payload: data,
  };
};
export const updateHR = (data) => {
  return {
    type: types.UPDATE_HR,
    payload: data,
  };
};
export const updateHRSuccess = (data) => {
  return {
    type: types.UPDATE_HR_SUCCESS,
    payload: data,
  };
};
export const updateHRError = (data) => {
  return {
    type: types.UPDATE_HR_ERROR,
    payload: data,
  };
};
export const resetPassword = (data) => {
  return {
    type: types.RESET_PASSWORD_HR,
    payload: data,
  };
};
export const resetPasswordError = (data) => {
  return {
    type: types.RESET_PASSWORD_ERROR,
    payload: data,
  };
};
export const deleteHR = (data) => {
  return {
    type: types.DELETE_HR,
    payload: data,
  };
};

export const deleteHRSuccess = (data) => {
  return {
    type: types.DELETE_HR_SUCCESS,
    payload: data,
  };
};

export const deleteHRError = (data) => {
  return {
    type: types.DELETE_HR_ERROR,
    payload: data,
  };
};
export const getHRPermission = (data) => {
  return {
    type: types.GET_HR_PERMISSION,
    payload: data,
  };
};
export const setHRPermissionSuccess = (data) => {
  return {
    type: types.SET_HR_PERMISSION_SUCCESS,
    payload: data,
  };
};
export const setHRPermissionError = (data) => {
  return {
    type: types.SET_HR_PERMISSION_ERROR,
    payload: data,
  };
};
