export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERR = 'LOGIN_ERR';
export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERR = 'LOGOUT_ERR';
export const START_LOGIN = 'START_LOGIN';
export const START_LOGOUT = 'START_LOGOUT';
export const REGISTER_USER = 'REGISTER_USER';
export const SET_USER = 'SET_USER';
export const SET_KPI = 'SET_KPI';
export const GET_KPI = 'GET_KPI';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCESS = 'FORGOT_PASSWORD_SUCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const OTP_VERIFIED = 'OTP_VERIFIED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCESS = 'CHANGE_PASSWORD_SUCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const RESET_ALL_DATA = 'RESET_ALL_DATA';
export const EMPLOYEE_REGISTER = 'EMPLOYEE_REGISTER';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const REGISTER_REDIRECTION = 'REGISTER_REDIRECTION';
export const VERIFY_OTP = 'VERIFY_OTP';
export const OTP_REDIRECTION = 'OTP_REDIRECTION';
export const ISMPINCREATED_REDIRECTION = 'ISMPINCREATED_REDIRECTION';
export const CREATE_MPIN = 'CREATE_MPIN';
export const CREATE_MPIN_REDIRECTION = 'CREATE_MPIN_REDIRECTION';
export const EMPLOYEE_LOGIN = 'EMPLOYEE_LOGIN';
export const EMPLOYEE_LOGIN_REDIRECTION = 'EMPLOYEE_LOGIN_REDIRECTION';
export const GET_EXPORT_PORTFOLIO = 'GET_EXPORT_PORTFOLIO';
export const SET_ACTIVE_URL = 'SET_ACTIVE_URL';
