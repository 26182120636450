/* eslint-disable no-plusplus */
/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};
const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else {
    return null;
  }

  return initials.toUpperCase();
};

const getQueryString = (params) => {
  const query = Object.keys(params).filter(
    (key) => params[key] !== undefined && params[key] !== null && params[key] !== '',
  );
  return query.map((key) => `${key}=${params[key]}`).join('&');
};

function combineFields(step1Data, step2Data) {
  const formData = new FormData();

  Object.keys(step1Data).forEach((field) => {
    if (field === 'policyDocuments') {
      step1Data[field].forEach((item) => {
        const file = item.originFileObj;
        formData.append([`step1[${field}][]`], file);
      });
    }
    if (field === 'neftDocuments') {
      step1Data[field].forEach((item) => {
        const file = item.originFileObj;
        formData.append([`step1[${field}][]`], file);
      });
    }

    if (field === 'policyPremiumByAge') {
      formData.append([`step1[${field}]`], JSON.stringify(step1Data[field]));
    }
    if (
      field !== 'policyDocuments' &&
      field !== 'neftDocuments' &&
      field !== 'ageMax' &&
      field !== 'ageMin' &&
      field !== 'policyPremiumByAge' &&
      field !== 'policyPremium' &&
      field !== 'sumInsured'
    ) {
      formData.append([`step1[${field}]`], step1Data[field]);
    }
  });

  Object.keys(step2Data).forEach((field) => {
    formData.append([`step2[${field}]`], step2Data[field]);
  });
  return formData;
}

function combineFieldsGTI(step1Data) {
  const formData = new FormData();

  Object.keys(step1Data).forEach((field) => {
    if (field === 'policyDocuments') {
      step1Data[field].forEach((item) => {
        const file = item.originFileObj;
        formData.append([`step1[${field}][]`], file);
      });
    }
    if (field === 'neftDocuments') {
      step1Data[field].forEach((item) => {
        const file = item.originFileObj;
        formData.append([`step1[${field}][]`], file);
      });
    }

    if (field === 'policyPremiumByAge') {
      formData.append([`step1[${field}]`], JSON.stringify(step1Data[field]));
    }
    if (
      field !== 'policyDocuments' &&
      field !== 'neftDocuments' &&
      field !== 'ageMax' &&
      field !== 'ageMin' &&
      field !== 'policyPremiumByAge' &&
      field !== 'policyPremium' &&
      field !== 'sumInsured'
    ) {
      formData.append([`step1[${field}]`], step1Data[field]);
    }
  });
  return formData;
}

function objectToFormData(data) {
  console.log('okoko', data);
  if (data?.step1?.policyType === 'GTI') {
    const GTIFormData = combineFieldsGTI(data?.step1);
    return GTIFormData;
  }
  const step1Data = data?.step1;
  const step2Data = data?.step2;

  // Combine fields from both steps into a single object
  const TotalData = combineFields(step1Data, step2Data);
  return TotalData;
}

// const capitalizeFirstLetterOfEachWord = (str) => {
//   return str.charAt(0).toUpperCase() + str.slice(1);
// };
function downloadPDF(pdfLink, title) {
  const anchor = document.createElement('a');
  anchor.href = pdfLink;
  anchor.target = '_blank';
  anchor.download = `${title}.pdf`;
  // Simulate a click on the anchor element to trigger the download
  anchor.click();
}

export {
  ellipsis,
  idGenerator,
  getInitials,
  getQueryString,
  // capitalizeFirstLetterOfEachWord,
  objectToFormData,
  downloadPDF,
};
export const UsersRole = {
  SUPER_ADMIN: 'super_admin',
  PRIMARY_HR: 'primary_hr',
  EMPLOYEE: 'employee',
  SECONDARY_HR: 'secondary_hr',
  CLAIM_CREATOR: 'claim',
  POLICY_CREATOR: 'policy',
  ENDORSEMENT_CREATOR: 'endorsement',
};

export const availablePermissions = {
  PROFILE: 'profile',
  ORGANIZATION_VIEW: 'organization_view',
  ORGANIZATION_ADD: 'organization_add',
  ORGANIZATION_EDIT: 'organization_edit',
  ORGANIZATION_DELETE: 'organization_delete',
  USER_ADD: 'user_add',
  USER_EDIT: 'user_edit',
  USER_VIEW: 'user_view',
  ADD_POLICY: 'policy_add',
  EDIT_POLICY: 'policy_edit',
  DELETE_POLICY: 'policy_delete',
  POLICY_VIEW: 'policy_view',
  POLICY_DOCUMENTS: 'policy_documents',
  POLICY_DOCUMENT_DOWNLOAD: 'policy_document_download',
  CLAIM_ADD: 'claim_add',
  CLAIM_EDIT: 'claim_edit',
  CLAIM_VIEW: 'claim_view',
  CLAIM_DELETE: 'claim_delete',
  ENDORSEMENT_ADD: 'endorsement_add',
  ENDORSEMENT_EDIT: 'endorsement_edit',
  ENDORSEMENT_VIEW: 'endorsement_view',
  ENDORSEMENT_DELETE: 'endorsement_delete',
  VIEW_HOSPITAL_NETWORKS: 'view_hospital_networks',
  CD_STATEMENT_VIEW: 'cd_statement_view',
  CD_STATEMENT_EDIT: 'cd_statement_edit',
  HR_VIEW: 'hr_view',
  HR_ADD: 'hr_add',
  HR_EDIT: 'hr_edit',
  SOS_VIEW: 'sos_view',
  CHANGE_PASSWORD: 'change_password',
  MIS: 'mis',
  MIS_UPLOAD: 'mis_upload',
  MEMBER: 'member',
  MEMBER_UPLOAD: 'member_upload',
  VIEW_SAMPLE_FILE: 'view_sample_file',
  VIEW_E_CARD: 'view_ecard',
  E_CARD_DOWNLOAD: 'ecard_download',
  SELF_MEMBER_LIST: 'self_member_list',
  SETTINGS: 'settings',
  CD_STATEMENT_ADD: 'cd_statement_add',
  TPA_ADD: 'tpa_add',
  TPA_EDIT: 'tpa_edit',
  TPA_DELETE: 'tpa_delete',
  TPA_VIEW: 'tpa_view',
  INSURANCE_ADD: 'insurance_add',
  INSURANCE_EDIT: 'insurance_edit',
  INSURANCE_VIEW: 'insurance_view',
  INSURANCE_DELETE: 'insurance_delete',
  MEMBER_DOWNLOAD: 'member_download',
  POLICY_DOCUMENT_UPLOAD: 'policy_document_upload',
  HR_LIST_VIEW: 'hr_list_view',
  HR_DELETE: 'hr_delete',
  RFQ_VIEW: 'rfq_view',
  RFQ_ADD: 'rfq_add',
  RFQ_EDIT: 'rfq_edit',
  RFQ_DELETE: 'rfq_delete',
  INSTRUCTIONS_VIEW: 'instructions_view',
  INSTRUCTIONS_EDIT: 'instructions_edit',

  DASHBOARD_VIEW: 'dashboard_view',
  MIS_EXPORT: 'mis_export',
};

// base 64

// Base64-encoded CSV data
// const base64Data = "IlBvbGljeSBOdW1iZXIiLCJFbXBsb3llZSBJRCIsIk1lbWJlciBOYW1lIiwiUmVsYXRpb24iLCJFbmRvcnNlbWVudCBUeXBlIiwiU3RhdHVzIiwiQ3JlYXRlZCBPbiIsIkFwcHJvdmVkIE9uIiwiUmVtYXJrcyIsIkNvcnJlY3Rpb24gVHlwZSIsIk9sZCBWYWx1ZSIsIk5ldyBWYWx1ZSIKIjM0MCIsIjMiLCJuayIsInNwb3VzZSIsIkFkZGl0aW9uIiwiQXBwcm92ZWQiLCIyMDI0LTAxLTEwIDE1OjQ5OjQxIiwiMjAyNC0wMS0xMCAxNzowODowNSIsIiIsIiIsIiIsIiIKIjM0MCIsIjMiLCJubiIsInNvbiIsIkFkZGl0aW9uIiwiQXBwcm92ZWQiLCIyMDI0LTAxLTEwIDE3OjA5OjM4IiwiMjAyNC0wMS0xMCAxNzoxMDowNiIsIiIsIiIsIiIsIiIKIjM0MCIsIjMiLCJramxqIiwic29uIiwiQWRkaXRpb24iLCJBcHByb3ZlZCIsIjIwMjQtMDEtMTAgMTc6MTA6MjkiLCIyMDI0LTAxLTEwIDE3OjExOjAwIiwiIiwiIiwiIiwiIgo="

// Base64 data here

// Decode the Base64 data

export const csvFileConverter = (base64Data) => {
  const decodedData = atob(base64Data);
  // Convert the decoded data to a Uint8Array
  const uint8Array = new Uint8Array(decodedData.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([uint8Array], { type: 'text/csv' });

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);

  // Set the file name (you can change it if needed)
  downloadLink.download = 'decoded_data.csv';

  // Append the link to the document
  document.body.appendChild(downloadLink);

  // Trigger a click on the link to start the download
  downloadLink.click();

  // Remove the link from the document
  document.body.removeChild(downloadLink);
};
//
export const fileConverter = (base64Data, fileName, fileType) => {
  const binaryString = atob(base64Data);

  // Create an array to hold byte values
  const bytes = new Uint8Array(binaryString.length);

  // Fill the array with byte values from the binary string
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the array
  const blob = new Blob([bytes], { type: fileType });

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);

  // Set the file name
  downloadLink.download = fileName;

  // Append the link to the document
  document.body.appendChild(downloadLink);

  // Trigger a click on the link to start the download
  downloadLink.click();

  // Remove the link from the document
  document.body.removeChild(downloadLink);
};

export const xlsFileConverter = (base64Data) => {
  const binaryString = atob(base64Data.data);

  // Create an array to hold byte values
  const bytes = new Uint8Array(binaryString.length);

  // Fill the array with byte values from the binary string
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the array
  const blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);

  // Set the file name (you can change it if needed)
  downloadLink.download = base64Data.message === 'MIS exported successfully.' ? 'Policy MIS' : 'Portfolio';

  // Append the link to the document
  document.body.appendChild(downloadLink);

  // Trigger a click on the link to start the download
  downloadLink.click();

  // Remove the link from the document
  document.body.removeChild(downloadLink);
};

export const zipFileConverter = (base64Data) => {
  const binaryString = atob(base64Data.file);

  // Create an array to hold byte values
  const bytes = new Uint8Array(binaryString.length);

  // Fill the array with byte values from the binary string
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Determine the file type based on the file name extension
  let fileType = '';
  const fileNameExtension = base64Data.fileName;
  if (fileNameExtension.endsWith('.pdf')) {
    fileType = 'application/pdf';
  } else if (fileNameExtension.endsWith('.zip')) {
    fileType = 'application/zip';
  } else {
    console.error('Unsupported file type');
    return;
  }

  // Create a Blob from the array
  const blob = new Blob([bytes], { type: fileType });

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);

  // Set the file name
  downloadLink.download = base64Data.fileName;

  // Append the link to the document
  document.body.appendChild(downloadLink);

  // Trigger a click on the link to start the download
  downloadLink.click();

  // Remove the link from the document
  document.body.removeChild(downloadLink);
};

// routes
export const availableSideBarRoutes = {
  DASHBOARD: 'dashboard',
  TPAs: 'tpas',
  INSURANCE_COMPANIES: 'insurance-companies',
  ORGANIZATION: 'organizations',
  RFQs: 'rfqs',
  INSTRUCTIONS: 'Instructions',
  POLICIES: 'policies',
  SECONDARY_HR_DASHBOARD: 'hrdashboard',
  HR_DASHBOARD: 'hr-dashboard',
  CD_STATEMENTS: 'cd-statements',
  HRS: 'HRs',
  HR_POLICIES: 'policy',
  CLAIMS: 'claim',
  CHANGE_PASSWORD: 'change-password',
};

export const fieldSize = {
  maxLength: '10000',
};
