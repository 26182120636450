/* eslint-disable import/no-cycle */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../tpa/actionTypes';
import {
  addTpaApi,
  deleteTpaApi,
  getAllTpasApi,
  getNextPageTpaApi,
  searchTpaApi,
  tpaActiveApi,
  updateTpaApi,
} from '../requests/tpaRequests';
import {
  activeTpaStatusError,
  activeTpaStatusSuccess,
  addTpaError,
  addTpaSuccess,
  deleteTpaError,
  deleteTpaSuccess,
  setAllTpas,
  setMetaParams,
  // updateTpaError,
  updateTpaSuccess,
} from '../../tpa/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';

function* addTpaHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addTpaApi, values?.payload);
    if (data?.status) {
      yield put(addTpaSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.meta_params.formErrors) {
      yield put(addTpaError(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addTpaError({}));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getTpasHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllTpasApi, {});
    if (data?.status) {
      yield put(setAllTpas(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addTpaError(data.message));
    }
  } catch (err) {
    yield put(addTpaError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* searchTpaHandler(values) {
  yield delay(500);

  yield startLoader('transparent');
  try {
    const { data } = yield call(searchTpaApi, values?.payload);
    if (data?.status) {
      yield put(setAllTpas(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addTpaError(data.message));
    }
  } catch (err) {
    yield put(addTpaError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* deleteTpaHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(deleteTpaApi, values?.payload);
    if (data?.status) {
      yield put(deleteTpaSuccess(values.payload));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(deleteTpaError(data.message));
    }
  } catch (err) {
    yield put(deleteTpaError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateTpaHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(updateTpaApi, values?.payload);
    if (data?.status) {
      yield put(updateTpaSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.meta_params.formErrors) {
      yield put(addTpaError(data.meta_params));
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addTpaError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* activeInactiveHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(tpaActiveApi, {
      id: values?.payload?.id,
      status: values?.payload?.status === 'inactive' ? 0 : 1,
    });
    if (data?.status) {
      yield put(activeTpaStatusSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(activeTpaStatusError(data.message));
    }
  } catch (err) {
    yield put(activeTpaStatusError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getNextTpaHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getNextPageTpaApi, value?.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllTpas(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addTpaError(data.message));
    }
  } catch (err) {
    yield put(addTpaError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* tpaSaga() {
  yield takeLatest(types.ADD_TPA, addTpaHandler);
  yield takeLatest(types.GET_ALL_TPA, getTpasHandler);
  yield takeLatest(types.SEARCH_TPA, searchTpaHandler);
  yield takeLatest(types.DELETE_TPA, deleteTpaHandler);
  yield takeLatest(types.UPDATE_TPA, updateTpaHandler);
  yield takeLatest(types.ACTIVE_TPA_STATUS, activeInactiveHandler);
  yield takeLatest(types.NEXT_PAGE_TPA, getNextTpaHandler);
}
