/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../employee/actionTypes';
import {
  addEmployeeApi,
  deleteEmployeeApi,
  employeeActiveApi,
  getAllEmployeeApi,
  getEmployeePermissionsApi,
  getNextPageEmployeeApi,
  searchEmployeeApi,
  updateEmployeeApi,
  resetPasswordApi,
  getEmployeePolicyListApi,
  getEmployeePolicyDetailApi,
  getEmployeeProfileApi,
  updateEmployeeProfileApi,
  registerEmployeeApi,
  updateEmployeeMobileApi,
} from '../requests/employeeRequests';
import {
  activeEmployeeStatusError,
  activeEmployeeStatusSuccess,
  addEmployeeError,
  addEmployeeSuccess,
  deleteEmployeeError,
  deleteEmployeeSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  setAllEmployee,
  setEmployeePermissions,
  setEmployeePolicyData,
  setEmployeePolicyDetail,
  setEmployeeProfile,
  setMetaParams,
  setMetaParamsError,
  setRegisterEmployeeAssessmentSuccess,
  setUpdateEmployeeProfileSuccess,
  setUpdateMobileSuccess,
  updateEmployeeError,
  updateEmployeeSuccess,
} from '../../employee/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';

function* addEmployeeHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addEmployeeApi, values?.payload);
    if (data?.status) {
      yield put(addEmployeeSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.meta_params.formErrors) {
      yield put(addEmployeeError(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addEmployeeError(err));
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getEmployeeHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllEmployeeApi, {});
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllEmployee(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addEmployeeError(data.message));
    }
  } catch (err) {
    yield put(addEmployeeError(err));
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* searchEmployeeHandler(values) {
  yield delay(500);
  yield startLoader('transparent');
  try {
    const { data } = yield call(searchEmployeeApi, values?.payload);
    if (data?.status) {
      yield put(setAllEmployee(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addEmployeeError(data.message));
    }
  } catch (err) {
    yield put(addEmployeeError(err));
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* deleteEmployeeHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(deleteEmployeeApi, values?.payload);
    if (data?.status) {
      yield put(deleteEmployeeSuccess(values.payload));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(deleteEmployeeError(data.message));
    }
  } catch (err) {
    yield put(deleteEmployeeError(err));
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateEmployeeHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(updateEmployeeApi, values?.payload);
    if (data?.status) {
      yield put(updateEmployeeSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.meta_params.formErrors) {
      yield put(addEmployeeError(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(updateEmployeeError(err));
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getNextEmployeeHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getNextPageEmployeeApi, value?.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllEmployee(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addEmployeeError(data.message));
    }
  } catch (err) {
    yield put(addEmployeeError(err));
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getEmployeePermissionsHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getEmployeePermissionsApi, {});
    if (data?.status) {
      yield put(setEmployeePermissions(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* activeInactiveHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(employeeActiveApi, {
      id: values?.payload?.id,
      status: values?.payload?.status === 'inactive' ? 0 : 1,
    });
    if (data?.status) {
      yield put(activeEmployeeStatusSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(activeEmployeeStatusError(data.message));
    }
  } catch (err) {
    yield put(activeEmployeeStatusError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* resetPasswordHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(resetPasswordApi, values);
    if (data?.status) {
      yield put(resetPasswordSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(resetPasswordError(data.message));
    }
  } catch (err) {
    yield put(resetPasswordError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* employeePolicyListHandler() {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getEmployeePolicyListApi);
    if (data.status) {
      yield put(setEmployeePolicyData(data.data));
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* employeePolicyDetailHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getEmployeePolicyDetailApi, value.payload);
    if (data.status) {
      yield put(setEmployeePolicyDetail(data.data));
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* employeeProfileHandler() {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getEmployeeProfileApi);
    if (data.status) {
      yield put(setEmployeeProfile(data.data));
    } else {
      yield put(setEmployeeProfile([]));
    }
  } catch (err) {
    yield put(setEmployeeProfile([]));

    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateEmployeeProfileHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateEmployeeProfileApi, value.payload);
    if (data.status) {
      localStorage.setItem('email', data.data.email);
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setUpdateEmployeeProfileSuccess(true));
      yield put(setEmployeeProfile(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setMetaParamsError(data.meta_params));
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* updateEmployeeMobileHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateEmployeeMobileApi, value.payload);
    if (data.status) {
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setUpdateMobileSuccess(true));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setMetaParamsError(data.meta_params));
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* registerEmployeeHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(registerEmployeeApi, value.payload);
    if (data.status) {
      yield setRegisterEmployeeAssessmentSuccess(true);
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setRegisterEmployeeAssessmentSuccess(true));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* employeeSaga() {
  yield takeLatest(types.ADD_EMPLOYEE, addEmployeeHandler);
  yield takeLatest(types.GET_ALL_EMPLOYEE, getEmployeeHandler);
  yield takeLatest(types.SEARCH_EMPLOYEE, searchEmployeeHandler);
  yield takeLatest(types.DELETE_EMPLOYEE, deleteEmployeeHandler);
  yield takeLatest(types.UPDATE_EMPLOYEE, updateEmployeeHandler);
  yield takeLatest(types.NEXT_PAGE_EMPLOYEE, getNextEmployeeHandler);
  yield takeLatest(types.GET_EMPLOYEE_PERMISSIONS, getEmployeePermissionsHandler);
  yield takeLatest(types.ACTIVE_EMPLOYEE_STATUS, activeInactiveHandler);
  yield takeLatest(types.RESET_PASSWORD, resetPasswordHandler);
  yield takeLatest(types.GET_EMPLOYEE_POLICY_LIST, employeePolicyListHandler);
  yield takeLatest(types.GET_POLICY_DETAIL_DATA, employeePolicyDetailHandler);
  yield takeLatest(types.GET_EMPLOYEE_PROFILE, employeeProfileHandler);
  yield takeLatest(types.UPDATE_EMPLOYEE_PROFILE, updateEmployeeProfileHandler);
  yield takeLatest(types.UPDATE_MOBILE_NUMBER, updateEmployeeMobileHandler);

  yield takeLatest(types.REGISTER_EMPLOYEE_ASSESSMENT, registerEmployeeHandler);
}
