/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../claims/actionTypes';

import {
  getAllClaimsApi,
  getAllStatusApi,
  getExportApi,
  getEmployeeApi,
  getInstructionApi,
  getPatientApi,
  raiseClaimApi,
  raiseCashlessClaimApi,
  updateStatusApi,
  updateInstructionsApi,
  getClaimsDocumentsApi,
  uploadClaimsDocumentsApi,
  getEmployeeSuperAdminApi,
  claimZipFileApi,
} from '../requests/claimRequests';
import {
  addClaimError,
  // raiseClaimSuccess,
  raiseClaimSuccessSecond,
  raiseCashlessClaimSuccessSecond,
  setAllClaims,
  setClaimDocuments,
  setEmployee,
  setInstructions,
  setMetaParams,
  setPatient,
  setSingleStatus,
  setStatus,
  updateInstructionsSuccess,
  updateStatusSuccess,
  uploadClaimDocumentsSuccess,
  // updateStatusSuccess,
} from '../../claims/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import { zipFileConverter } from '../../../utility/utility';

function* getClaimHandler(action) {
  yield startLoader('transparent');
  yield delay(500);
  try {
    const { data } = yield call(getAllClaimsApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllClaims(data.data));
    } else {
      yield put(setAllClaims([]));
      yield put(addClaimError(data.meta_params));
      // yield toast.error(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getExportHandler(action) {
  yield delay(100);
  try {
    const { data } = yield call(getExportApi, action.payload);
    if (data?.status) {
      window.open(data.data, '_blank');
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
function* getStatusHandler(action) {
  yield delay(100);
  try {
    const { data } = yield call(getAllStatusApi, action.payload);
    if (data?.status) {
      yield put(setStatus(data.data));
    } else {
      yield put(setStatus(data.data));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setStatus([]));

    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* updateStatusHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateStatusApi, action.payload);
    if (data?.status) {
      yield put(updateStatusSuccess(true));
      yield put(setSingleStatus(data.data));

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getEmployeeHandler(action) {
  yield startLoader('transparent');
  yield delay(100);
  try {
    const { data } = yield call(getEmployeeApi, action.payload);
    if (data?.status) {
      yield put(setEmployee(data.data));
      // yield toast.success(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      yield put(setEmployee([]));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getEmployeeSuperAdminHandler(values) {
  yield startLoader('transparent');
  yield delay(100);
  try {
    const { data } = yield call(getEmployeeSuperAdminApi, values.payload);
    if (data?.status) {
      yield put(setEmployee(data.data));
      // yield toast.success(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getInstructionHandler(action) {
  yield startLoader('transparent');
  yield delay(100);
  try {
    const { data } = yield call(getInstructionApi, action.payload);
    if (data?.status) {
      yield put(setInstructions(data.data));
      // yield toast.success(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getPatientHandler(action) {
  yield startLoader('transparent');
  yield delay(100);
  try {
    const { data } = yield call(getPatientApi, action.payload);
    if (data?.status) {
      yield put(setPatient(data.data));
      // yield toast.success(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      yield put(setPatient([]));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* raiseClaimHandler(action) {
  yield startLoader('transparent');
  yield delay(100);
  yield startLoader('transparent');
  try {
    const { data } = yield call(raiseClaimApi, action.payload);
    if (data?.status) {
      yield put(raiseClaimSuccessSecond(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addClaimError(err));
    // yield toast.error(err.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  } finally {
    yield stopLoader('transparent');
  }
}
function* raiseCashlessClaimHandler(action) {
  yield startLoader('transparent');
  yield delay(100);
  yield startLoader('transparent');
  try {
    const { data } = yield call(raiseCashlessClaimApi, action.payload);
    if (data?.status) {
      yield put(raiseCashlessClaimSuccessSecond(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addClaimError(err));
    // yield toast.error(err.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateInstructionsHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateInstructionsApi, action.payload);
    if (data?.status) {
      yield put(updateInstructionsSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addClaimError(err));
    // yield toast.error(err.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getClaimsDocumentsHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getClaimsDocumentsApi, action.payload);
    if (data?.status) {
      yield put(setClaimDocuments(data.data));
      // yield toast.success(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      yield put(setClaimDocuments([]));

      // yield toast.error(data.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  } catch (err) {
    // yield toast.error(err.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  } finally {
    yield stopLoader('transparent');
  }
}
function* uploadClaimsDocumentsHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(uploadClaimsDocumentsApi, action.payload);
    if (data?.status) {
      yield put(uploadClaimDocumentsSuccess(data.status));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setClaimDocuments([]));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* claimZipFileHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(claimZipFileApi, action.payload);
    if (data?.status) {
      // yield put(uploadClaimDocumentsSuccess(data.status));
      yield zipFileConverter(data.data);
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // yield put(setClaimDocuments([]));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
export default function* claimSaga() {
  yield takeLatest(types.GET_ALL_CLAIMS, getClaimHandler);
  yield takeLatest(types.GET_EXPORT_CLAIMS, getExportHandler);
  yield takeLatest(types.GET_ALL_STATUS, getStatusHandler);
  yield takeLatest(types.UPDATE_STATUS, updateStatusHandler);
  yield takeLatest(types.GET_EMPLOYEE, getEmployeeHandler);
  yield takeLatest(types.GET_EMPLOYEE_SUPER_ADMIN, getEmployeeSuperAdminHandler);
  yield takeLatest(types.GET_INSTRUCTIONS, getInstructionHandler);
  yield takeLatest(types.GET_PATIENT, getPatientHandler);
  yield takeLatest(types.RAISE_CLAIM, raiseClaimHandler);
  yield takeLatest(types.RAISE_CASHLESS_CLAIM, raiseCashlessClaimHandler);
  yield takeLatest(types.UPDATE_INSTUCTIONS, updateInstructionsHandler);
  yield takeLatest(types.GET_CLAIMS_DOCUMENTS, getClaimsDocumentsHandler);
  yield takeLatest(types.UPLOAD_CLAIMS_DOCUMENTS, uploadClaimsDocumentsHandler);
  yield takeLatest(types.CLAIM_ZIP_FILE, claimZipFileHandler);
}
