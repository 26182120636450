import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import { UilKeySkeletonAlt, UilSignout, UilUser } from '@iconscout/react-unicons';

import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { startLogOut } from '../../../redux/authentication/actions';
import UserImage from '../../../static/img/avatar/profileImage.png';
import { availablePermissions } from '../../../utility/utility';

const AuthInfo = React.memo(() => {
  const { auth } = useSelector((state) => state);
  const permission = useSelector((state) => state.auth.user?.permissions);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      setUserData(auth?.user);
    }
  }, [auth]);
  const role = localStorage.getItem('role');

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(startLogOut());
    if (role === 'employee') {
      navigate('/employee-login');
      localStorage.removeItem('role');
    } else {
      navigate('/');
    }
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links mb-0 w-105">
          {permission?.includes(availablePermissions?.PROFILE) && (
            <li>
              <Link to="/profile">
                <UilUser /> Profile
              </Link>
            </li>
          )}

          {permission?.includes(availablePermissions?.SETTINGS) && (
            <li>
              <Link to="/settings">
                <UilSetting /> Settings
              </Link>
            </li>
          )}
          {permission?.includes(availablePermissions?.CHANGE_PASSWORD) && (
            <li>
              <Link to="/resetPassword">
                <UilKeySkeletonAlt /> Change Password
              </Link>
            </li>
          )}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src={UserImage} />
            <span className="ninjadash-nav-actions__author--name">{userData?.name}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
