import {
  SET_IMAGE_LOADER,
  SET_LIST_LOADER,
  SET_PAGE_LOADER,
  SET_SEARCH_LOADER,
  SET_TRANSPARENT_LOADER,
} from './loaderAction';

const initialState = {
  pageLoader: false,
  searchLoader: false,
  listLoader: false,
  transparentLoader: false,
  imageLoader: false,
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_LOADER:
      return {
        ...state,
        searchLoader: action.payload,
      };

    case SET_PAGE_LOADER:
      return {
        ...state,
        pageLoader: action.payload,
      };

    case SET_LIST_LOADER:
      return {
        ...state,
        listLoader: action.payload,
      };

    case SET_TRANSPARENT_LOADER:
      return {
        ...state,
        transparentLoader: action.payload,
      };

    case SET_IMAGE_LOADER:
      return {
        ...state,
        imageLoader: action.payload,
      };

    default:
      return state;
  }
};
