import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { employeeLoginRedirection } from '../../redux/authentication/actions';
import { UsersRole } from '../../utility/utility';

function ProtectedRoute({ Component, path }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = useLocation();

  const employeeLoginValue = useSelector((state) => state?.auth?.employeeLogin);
  const role = useSelector((state) => state?.auth.user?.role);
  const Id = useSelector((state) => state?.auth.user?.organization?.id);
  const loginRedirection = useSelector((state) => state?.auth.login);
  // const policyGMCId = useSelector((state) => state?.auth.user?.policyGMCId);

  const pathName = routes.pathname;

  useEffect(() => {
    if (employeeLoginValue) {
      navigate('/policies');
      dispatch(employeeLoginRedirection(false));
    }
  }, [employeeLoginValue]);

  useEffect(() => {
    if (
      loginRedirection &&
      (role === UsersRole.SUPER_ADMIN ||
        role === UsersRole.CLAIM_CREATOR ||
        role === UsersRole.ENDORSEMENT_CREATOR ||
        role === UsersRole.POLICY_CREATOR)
    ) {
      if (pathName === '/') {
        navigate('/dashboard');
      } else if (pathName) {
        navigate(pathName);
      }
    }
  }, [loginRedirection, pathName]);

  useEffect(() => {
    if (role === UsersRole.PRIMARY_HR) {
      if (pathName === '/') {
        navigate(`/hr-dashboard/${Id}`);
        // navigate(`/organizations/${Id}/policy/${policyGMCId}`);
      } else if (pathName) {
        navigate(pathName);
      }
    } else if (role === UsersRole.SECONDARY_HR) {
      if (pathName === '/') {
        navigate(`/hrdashboard/${Id}`);
        // You can also add other conditions for SECONDARY_HR if needed
      } else if (pathName) {
        navigate(pathName);
      }
    }
  }, [role, Id]);

  const isLoggedIn = useSelector((state) => state.auth.login);
  return isLoggedIn ? (
    <Routes>
      {' '}
      <Route element={<Component />} path={path} />{' '}
    </Routes>
  ) : (
    <Routes>
      {' '}
      <Route path="/ninjadash-react/admin" element={<Navigate to="/" />} />
    </Routes>
  );
}

ProtectedRoute.propTypes = {
  Component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
