import * as types from './actionTypes';

export const addTpa = (data) => {
  return {
    type: types.ADD_TPA,
    payload: data,
  };
};

export const addTpaSuccess = (data) => {
  return {
    type: types.ADD_TPA_SUCCESS,
    data,
  };
};

export const addTpaError = (data) => {
  return {
    type: types.ADD_TPA_ERROR,
    data,
  };
};

export const getAllTpas = () => {
  return {
    type: types.GET_ALL_TPA,
  };
};

export const setAllTpas = (data) => {
  return {
    type: types.SET_ALL_TPA,
    data,
  };
};

export const searchTpa = (data) => {
  return {
    type: types.SEARCH_TPA,
    payload: data,
  };
};

export const deleteTpa = (data) => {
  return {
    type: types.DELETE_TPA,
    payload: data,
  };
};

export const deleteTpaSuccess = (data) => {
  return {
    type: types.DELETE_TPA_SUCCESS,
    payload: data,
  };
};

export const deleteTpaError = (data) => {
  return {
    type: types.DELETE_TPA_ERROR,
    payload: data,
  };
};

export const updateTpa = (data) => {
  return {
    type: types.UPDATE_TPA,
    payload: data,
  };
};

export const updateTpaSuccess = (data) => {
  return {
    type: types.UPDATE_TPA_SUCCESS,
    payload: data,
  };
};

export const updateTpaError = (data) => {
  return {
    type: types.UPDATE_TPA_ERROR,
    data,
  };
};

export const resetUpdateTpa = (data) => {
  return {
    type: types.UPDATE_TPA_RESET,
    payload: data,
  };
};

export const resetCreateTpa = (data) => {
  return {
    type: types.ADD_TPA_RESET,
    payload: data,
  };
};

export const activeTpaStatus = (data) => {
  return {
    type: types.ACTIVE_TPA_STATUS,
    payload: data,
  };
};

export const activeTpaStatusSuccess = (data) => {
  return {
    type: types.ACTIVE_TPA_STATUS_SUCCESS,
    payload: data,
  };
};

export const activeTpaStatusError = (data) => {
  return {
    type: types.ACTIVE_TPA_STATUS_ERROR,
    payload: data,
  };
};

export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const getNextPageTpa = (data) => {
  return {
    type: types.NEXT_PAGE_TPA,
    payload: data,
  };
};
