/* eslint-disable no-case-declarations */
import * as types from './actionTypes';

const initState = {
  loading: false,
  allTPA: [],
  created: false,
  updated: false,
  metaParams: {},
  errors: {},
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.ADD_TPA:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_TPA_SUCCESS:
      let addNewParams = {};
      if (state.metaParams.pagination.total_count) {
        addNewParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count + 1 };
      }
      return {
        ...state,
        loading: false,
        created: data.status,
        allTPA: [data.data, ...state.allTPA],
        metaParams: { pagination: { ...addNewParams } },
      };
    case types.ADD_TPA_ERROR:
      return {
        ...state,
        loading: false,
        errors: data?.formErrors,
      };
    case types.GET_ALL_TPA:
      return {
        ...state,
        loading: true,
      };
    case types.SET_ALL_TPA:
      return {
        ...state,
        loading: false,
        allTPA: data,
      };
    case types.SEARCH_TPA:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_TPA:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_TPA_SUCCESS:
      let remainingData = '';
      let newParams = {};
      if (action.payload) {
        remainingData = state.allTPA.filter((item) => item.id !== action.payload);
        if (state.metaParams.pagination.total_count) {
          newParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count - 1 };
        }
      }
      return {
        ...state,
        allTPA: [...remainingData],
        loading: false,
        metaParams: { pagination: { ...newParams } },
      };
    case types.UPDATE_TPA:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_TPA_SUCCESS:
      let updatedData = '';
      if (action.payload) {
        updatedData = state.allTPA.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allTPA: [...updatedData],
        updated: true,
      };
    case types.UPDATE_TPA_ERROR:
      return {
        ...state,
        loading: false,
        errors: data,
      };
    case types.ADD_TPA_RESET:
      return {
        ...state,
        loading: false,
        created: false,
      };
    case types.UPDATE_TPA_RESET:
      return {
        ...state,
        loading: false,
        updated: false,
      };
    case types.ACTIVE_TPA_STATUS:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVE_TPA_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ACTIVE_TPA_STATUS_SUCCESS:
      let activeUpdatedData = '';
      if (action.payload) {
        activeUpdatedData = state.allTPA.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allTPA: [...activeUpdatedData],
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    default:
      return state;
  }
};
export default AuthReducer;
