export const ADD_CD = 'ADD_CD';
export const UPDATE_ENDOR = 'UPDATE_ENDOR';

export const SET_CD = 'SET_CD';
export const ADD_CD_SUCCESS = 'ADD_CD_SUCCESS';
export const UPDATE_ENDOR_SUCCESS = 'UPDATE_ENDOR_SUCCESS';

export const UPDATE_CD = 'UPDATE_CD';
export const UPDATE_CD_SUCCESS = 'UPDATE_CD_SUCCESS';
export const GET_EXPORT_CD = 'GET_EXPORT_CD';

export const GET_ALL_CD = 'GET_ALL_CD';
export const SET_ALL_CD = 'SET_ALL_CD';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const ADD_CD_ENDT_STATEMENT = 'ADD_CD_ENDT_STATEMENT';
