/* eslint-disable import/no-cycle */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../policy/actionTypes';
import {
  addPolicyMemberError,
  addPolicyMemberSuccess,
  SetInsuranceProvider,
  SetTpaList,
  addPolicyError,
  addPolicySuccess,
  getPolicyError,
  setAllPolicy,
  setExportPolicyData,
  setExportPolicyDataError,
  setPolicySuccess,
  setPolicyError,
  setMetaParams,
  getHospitalListSuccess,
  getHospitalListError,
  downloadPolicyDocSuccess,
  addMemberSampleSuccess,
  addMISSuccess,
  addMISError,
  setMemberListsuccess,
  setHrECardList,
  setPolicyMemberList,
  sosSuccess,
} from '../../policy/actions';
import {
  addPolicyApi,
  getAllPolicyApi,
  getExportPolicyDataApi,
  getInsuranceProviderListApi,
  getTpaListApi,
  addPolicyMemberApi,
  viewPolicyApi,
  getHospitalListApi,
  getPolicyDocApi,
  getAddmemberSampleApi,
  getMemberListApi,
  addMISApi,
  getEmployeeECardDownloadApi,
  getHrECardDownloadApi,
  getPolicyMemberListApi,
  addSosApi,
  getExportMisApi,
} from '../requests/policyRequests';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import { fileConverter } from '../../../utility/utility';

//

function* getPolicyHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllPolicyApi, values?.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllPolicy(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getPolicyError(data.message));
    }
  } catch (err) {
    yield put(getPolicyError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

// add policy handler
function* addPolicyMemberHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addPolicyMemberApi, values?.payload);
    if (data?.status) {
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addPolicyMemberSuccess(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addPolicyMemberError(data.meta_params));
    }
  } catch (err) {
    yield put(addPolicyMemberError(err));
    yield toast.error(err.response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* viewPolicyHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(viewPolicyApi, values?.payload);
    if (data?.status) {
      yield put(setPolicySuccess(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setPolicyError(data.message));
    }
  } catch (err) {
    yield put(setPolicyError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getExportPolicyHandler(values) {
  yield delay(100);
  yield startLoader('transparent');

  try {
    const { data } = yield call(getExportPolicyDataApi, values?.payload);
    if (data?.status) {
      yield put(setExportPolicyData(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setExportPolicyDataError(data.message));
    }
  } catch (err) {
    yield put(setExportPolicyDataError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getInsuranceListHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getInsuranceProviderListApi);
    if (data.status) {
      yield put(SetInsuranceProvider(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getTpaListHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getTpaListApi);
    if (data.status) {
      yield put(SetTpaList(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* addPolicyHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addPolicyApi, values);
    if (data.status) {
      yield put(addPolicySuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.meta_params.formErrors) {
      yield put(addPolicyError(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addPolicyError());
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* networkHospitalHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getHospitalListApi, values.payload); // Make API call
    if (data.status) {
      yield put(getHospitalListSuccess(data.data)); // Dispatch success action with data

      // Call the callback function with the response, if it exists
      if (values.callback) {
        values.callback(data);
      }
    } else {
      yield put(getHospitalListError());
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(getHospitalListError());
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* downloadPolicyDocHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getPolicyDocApi, values.payload);
    if (data.status) {
      yield put(downloadPolicyDocSuccess(data.data));
    } else {
      yield put(getHospitalListError());
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(getHospitalListError());
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* sampleAddMemberHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getAddmemberSampleApi, values.payload);
    if (data.status) {
      window.open(data.data, '_blank');
      yield put(addMemberSampleSuccess(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* viewMemberListHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getMemberListApi, values.payload);
    if (data.status) {
      window.open(data.data, '_blank');
      yield put(setMemberListsuccess(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* employeeECardDownloadHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getEmployeeECardDownloadApi, values.payload);
    if (data.status) {
      if (data.data) {
        const pdfUrl = atob(data.data);
        // window.open(pdfUrl, '_blank');
        const response = yield fetch(pdfUrl);
        const blob = yield response.blob();

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.download = 'Insurance Card.pdf';

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

// function* employeeECardDownloadHandler(values) {
//   yield startLoader('transparent');

//   try {
//     const { data } = yield call(getEmployeeECardDownloadApi, values.payload);
//     if (data.status) {
//       window.open(data.data, '_blank');
//     } else {
//       yield toast.error(data.message, {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     }
//   } catch (err) {
//     yield toast.error(err.message, {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   } finally {
//     yield stopLoader('transparent');
//   }
// }

function* hrECardDownloadHandler(values) {
  yield delay(500);
  yield startLoader('transparent');

  try {
    const { data } = yield call(getHrECardDownloadApi, values.payload);
    if (data.status) {
      yield put(setHrECardList(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    // yield toast.error(err.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  } finally {
    yield stopLoader('transparent');
  }
}

function* addMISHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addMISApi, values.payload);
    if (data.status) {
      yield put(addMISSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(addMISError(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getPolicyMemberListHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getPolicyMemberListApi);
    if (data.status) {
      yield put(setPolicyMemberList(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* addSosHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addSosApi, values.payload);
    if (data.status) {
      yield put(sosSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getExportMisHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getExportMisApi, values.payload);
    if (data.status) {
      // yield xlsFileConverter(data);

      if (data.data.excel_detail_file) {
        yield fileConverter(
          data.data.excel_detail_file,
          'Policy Detail MIS.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
      }

      // Download PDF file
      if (data.data.excel_file) {
        yield fileConverter(
          data.data.excel_file,
          'Policy MIS.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
      }

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

// Utility function to handle file conversion and download

export default function* policySaga() {
  yield takeLatest(types.GET_ALL_POLICY, getPolicyHandler);
  yield takeLatest(types.GET_EXPORT_POLICY_DATA, getExportPolicyHandler);
  yield takeLatest(types.ADD_POLICY_MEMBER, addPolicyMemberHandler);
  yield takeLatest(types.GET_INSURANCE_PROVODERS, getInsuranceListHandler);
  yield takeLatest(types.GET_TPA_LISTS, getTpaListHandler);
  yield takeLatest(types.ADD_POLICY, addPolicyHandler);
  yield takeLatest(types.VIEW_POLICY, viewPolicyHandler);
  yield takeLatest(types.NETWORK_HOSPITAL, networkHospitalHandler);
  yield takeLatest(types.DOWNLOAD_POLICY_DOC, downloadPolicyDocHandler);
  yield takeLatest(types.SAMPLE_ADD_MEMBER, sampleAddMemberHandler);
  yield takeLatest(types.VIEW_MEMBER_LIST, viewMemberListHandler);
  yield takeLatest(types.ADD_MIS, addMISHandler);
  yield takeLatest(types.EMPLOYEE_E_CARD_DOWNLOAD, employeeECardDownloadHandler);
  yield takeLatest(types.HR_E_CARD_DOWNLOAD, hrECardDownloadHandler);
  yield takeLatest(types.GET_POLICY_MEMBER_LIST, getPolicyMemberListHandler);
  yield takeLatest(types.ADD_SOS, addSosHandler);
  yield takeLatest(types.GET_EXPORT_MIS, getExportMisHandler);
}
