export const ADD_INSURANCE = 'ADD_INSURANCE';
export const ADD_INSURANCE_SUCCESS = 'ADD_INSURANCE_SUCCESS';
export const ADD_INSURANCE_ERROR = 'ADD_INSURANCE_ERROR';
export const GET_ALL_INSURANCE = 'GET_ALL_INSURANCE';
export const SET_ALL_INSURANCE = 'SET_ALL_INSURANCE';
export const SEARCH_INSURANCE = 'SEARCH_INSURANCE';
export const DELETE_INSURANCE = 'DELETE_INSURANCE';
export const DELETE_INSURANCE_SUCCESS = 'DELETE_INSURANCE_SUCCESS';
export const DELETE_INSURANCE_ERROR = 'DELETE_INSURANCE_ERROR';
export const UPDATE_INSURANCE = 'UPDATE_INSURANCE';
export const UPDATE_INSURANCE_SUCCESS = 'UPDATE_INSURANCE_SUCCESS';
export const UPDATE_INSURANCE_ERROR = 'UPDATE_INSURANCE_ERROR';
export const UPDATE_INSURANCE_RESET = 'UPDATE_INSURANCE_RESET';
export const ACTIVE_INSURANCE_STATUS = 'ACTIVE_INSURANCE_STATUS';
export const ACTIVE_INSURANCE_STATUS_SUCCESS = 'ACTIVE_INSURANCE_STATUS_SUCCESS';
export const ACTIVE_INSURANCE_STATUS_ERROR = 'ACTIVE_INSURANCE_STATUS_ERROR';
export const ADD_INSURANCE_RESET = 'ADD_INSURANCE_RESET';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const NEXT_PAGE_INSURANCE = 'NEXT_PAGE_INSURANCE';
