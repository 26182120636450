/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { getQueryString } from '../../../utility/utility';

export const addInsuranceApi = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('logo', values.logo);
  return DataService.post('/insurance-companies', formData);
};

export const getAllInsuranceApi = () => {
  return DataService.get('/insurance-companies');
};

export const searchInsuranceApi = (values) => {
  return DataService.get(`/insurance-companies?${getQueryString(values)}`);
};

export const deleteInsuranceApi = (values) => {
  return DataService.delete(`/insurance-companies/${values}`);
};

export const insuranceActiveApi = (values) => {
  return DataService.put(`/insurance-companies/${values.id}/update-status`, values);
};

export const updateInsuranceApi = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('_method', 'PUT');
  if (values.logo) {
    formData.append('logo', values.logo);
  }
  return DataService.post(`/insurance-companies/${values.id}`, formData);
};

export const getNextPageInsuranceApi = (values) => {
  return DataService.get(`/insurance-companies?${getQueryString(values)}`);
};
