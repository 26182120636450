/* eslint-disable no-case-declarations */
import * as types from './actionTypes';

const initState = {
  loading: false,
  allEmployee: [],
  created: false,
  updated: false,
  metaParams: {},
  permissions: [],
  errors: {},
  employeePolicyData: [],
  employeePolicyDetailData: [],
  employeeProfile: {},
  employeeProfileSuccess: false,
  registerEmployeeAssessmentSuccess: false,
  employeeMobileSuccess: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.ADD_EMPLOYEE:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_EMPLOYEE_SUCCESS:
      let addNewParams = {};
      if (state.metaParams.pagination.total_count) {
        addNewParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count + 1 };
      }
      return {
        ...state,
        loading: false,
        created: true,
        allEmployee: [data.data, ...state.allEmployee],
        metaParams: { pagination: { ...addNewParams } },
      };
    case types.ADD_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        errors: data?.formErrors,
      };
    case types.GET_ALL_EMPLOYEE:
      return {
        ...state,
        loading: true,
      };
    case types.SET_ALL_EMPLOYEE:
      return {
        ...state,
        loading: false,
        allEmployee: data,
      };
    case types.SEARCH_EMPLOYEE:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_EMPLOYEE:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_EMPLOYEE_SUCCESS:
      let remainingData = '';
      let newParams = '';
      if (action.payload) {
        remainingData = state.allEmployee.filter((item) => item.id !== action.payload);
        if (state.metaParams.pagination.total_count) {
          newParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count - 1 };
        }
      }
      return {
        ...state,
        allEmployee: [...remainingData],
        loading: false,
        metaParams: { pagination: { ...newParams } },
      };
    case types.DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_EMPLOYEE_RESET:
      return {
        ...state,
        loading: false,
        created: false,
      };
    case types.UPDATE_EMPLOYEE_RESET:
      return {
        ...state,
        loading: false,
        updated: false,
      };
    case types.UPDATE_EMPLOYEE:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_EMPLOYEE_SUCCESS:
      let updatedData = '';
      if (action.payload) {
        updatedData = state.allEmployee.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allEmployee: [...updatedData],
        updated: true,
      };
    case types.UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        errors: data?.formErrors,
      };
    case types.ACTIVE_EMPLOYEE_STATUS:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVE_EMPLOYEE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ACTIVE_EMPLOYEE_STATUS_SUCCESS:
      let activeUpdatedData = '';
      if (action.payload) {
        activeUpdatedData = state.allEmployee.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allEmployee: [...activeUpdatedData],
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.SET_EMPLOYEE_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case types.SET_EMPLOYEE_POLICY_DATA:
      return {
        ...state,
        employeePolicyData: action.payload,
      };
    case types.SET_POLICY_DETAIL_DATA:
      return {
        ...state,
        employeePolicyDetailData: action.payload,
      };
    case types.SET_EMPLOYEE_PROFILE:
      return {
        ...state,
        employeeProfile: action.payload,
      };
    case types.SET_UPDATE_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        employeeProfileSuccess: action.payload,
      };
    case types.SET_REGISTER_EMPLOYEE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        registerEmployeeAssessmentSuccess: action.payload,
      };

    case types.SET_UPDATE_MOBILE_SUCCESS:
      return {
        ...state,
        employeeMobileSuccess: action.payload,
      };
    case types.SET_META_PARAMS_ERROR:
      return {
        ...state,
        metaParams: data,
      };

    default:
      return state;
  }
};
export default AuthReducer;
