// import Cookies from 'js-cookie';
import * as types from './actionTypes';

const initState = {
  login: false,
  loading: false,
  error: null,
  user: {},
  register: false,
  otp: false,
  isMpinCreated: false,
  createMpin: false,
  employeeLogin: false,
  kpi: {},
  activeUrl: '',
  metaParams: {},
  status: {
    email: false,
    otp: false,
    redirect: false,
  },
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case types.LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case types.LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case types.LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case types.LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        login: true,
      };
    case types.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.FORGOT_PASSWORD_SUCESS:
      return {
        ...state,
        loading: false,
        status: {
          email: false,
          otp: false,
          redirect: action.payload,
        },
      };
    case types.EMAIL_VERIFIED:
      return {
        ...state,
        loading: false,
        status: { ...state.status, email: data },
      };
    case types.OTP_VERIFIED:
      return {
        ...state,
        loading: false,
        status: { ...state.status, otp: data },
      };
    case types.CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case types.CHANGE_PASSWORD_SUCESS:
      return {
        ...state,
        loading: false,
        login: false,
        status: {
          email: false,
          otp: false,
          redirect: data,
        },
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.REGISTER_REDIRECTION:
      return {
        ...state,
        register: action.payload,
      };
    case types.OTP_REDIRECTION:
      return {
        ...state,
        otp: action.payload,
      };
    case types.ISMPINCREATED_REDIRECTION:
      return {
        ...state,
        isMpinCreated: action.payload,
      };
    case types.CREATE_MPIN_REDIRECTION:
      return {
        ...state,
        createMpin: action.payload,
      };
    case types.EMPLOYEE_LOGIN_REDIRECTION:
      return {
        ...state,
        employeeLogin: action.payload,
      };
    case types.SET_KPI:
      return {
        ...state,
        kpi: action.payload,
      };
    case types.SET_ACTIVE_URL:
      return {
        ...state,
        activeUrl: action.payload,
      };
    default:
      return state;
  }
};
export default AuthReducer;
