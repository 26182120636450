import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UsersRole } from '../../utility/utility';

const Dashboard = lazy(() => import('../../container/pages/Dashboard'));
const Organization = lazy(() => import('../../container/pages/Organizations'));
const ResetPassword = lazy(() => import('../../container/profile/authentication/overview/ResetPassword'));
const Tpa = lazy(() => import('../../container/pages/Tpa'));
const Insurance = lazy(() => import('../../container/pages/Insurance'));
const Claims = lazy(() => import('../../container/pages/Claims'));
const Settings = lazy(() => import('../../container/pages/Settings'));
const OrganizationView = lazy(() => import('../../components/organization-view/OrganizationView'));
const HrOrganizationView = lazy(() => import('../../components/hrOrganizationView/HrOrganizationView'));
const HRManagement = lazy(() => import('../../container/pages/HRManagement'));
const CdStatement = lazy(() => import('../../container/pages/CdStatement'));
const Policy = lazy(() => import('../../container/pages/Policy'));
const ClaimList = lazy(() => import('../../container/pages/ClaimList'));
const PolicyForm = lazy(() => import('../../components/policy-form/PolicyForm'));
const PolicyView = lazy(() => import('../../components/policy-view/PolicyView'));
const PolicyNew = lazy(() => import('../../components/policy-view-new/PolicyNew'));

const EmployeePolicyList = lazy(() => import('../../container/pages/EmployeePolicyList'));
const Profile = lazy(() => import('../../container/profile/settings/overview/Profile'));
const ECards = lazy(() => import('../../container/pages/ECards'));
const Rfqs = lazy(() => import('../../container/pages/Rfqs'));
const RfqsList = lazy(() => import('../../container/pages/RfqsList'));
const Instructions = lazy(() => import('../../container/pages/Instructions'));
const RfqForm = lazy(() => import('../../form/RfqForm'));
const Endorsement = lazy(() => import('../../container/pages/Endorsement'));
const AdminEndorsement = lazy(() => import('../../container/pages/AdminEndorsement'));

// const PolicyNew = lazy(() => import('../../container/pages/PolicyNew'));

function PagesRoute({ collapsed }) {
  const role = useSelector((state) => state?.auth.user?.role);
  return (
    <Routes>
      {/* employee routes */}
      <Route path="policies" element={<EmployeePolicyList />} />
      <Route path="policy-details/:id" element={<PolicyView />} />
      <Route path="profile" element={<Profile />} />
      <Route path="endorsement" element={<Endorsement />} />
      <Route path="endorsement/:id" element={<Endorsement />} />

      {/* primary hr routes */}
      <Route path="hr-dashboard/:id" element={<HrOrganizationView />} />
      {/* {New hr dashboard route} */}
      <Route path="hrdashboard/:id" element={<OrganizationView />} />
      {/* <Route index element={<Dashboard />} /> */}
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="organizations" element={<Organization />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="tpas" element={<Tpa />} />
      <Route path="claims" element={<Claims />} />
      <Route path="admin-endorsement" element={<AdminEndorsement />} />
      <Route path="insurance-companies" element={<Insurance />} />
      <Route path="settings" element={<Settings />} />
      <Route path="organizations/:id/HRs" element={<HRManagement />} />
      <Route path="organizations/:id/cd-statement" element={<CdStatement />} />

      <Route path="organizations/:id" element={<OrganizationView />} />
      <Route path="organizations/:id/policy" element={<Policy />} />
      <Route path="organizations/:id/claim" element={<ClaimList />} />
      <Route path="organizations/:id/policy/:id/claim" element={<ClaimList />} />
      <Route path="organizations/:id/policy/add-policy" element={<PolicyForm />} />

      {role === UsersRole.PRIMARY_HR ? (
        <Route path="organizations/:id/policy/:number" element={<PolicyNew collapsed={collapsed} />} />
      ) : (
        <Route path="organizations/:id/policy/:number" element={<PolicyView />} />
      )}
      <Route path="e-cards/:id" element={<ECards />} />
      <Route path="rfqs" element={<Rfqs />} />
      <Route path="rfq-list" element={<RfqsList />} />
      <Route path="rtf-form" element={<RfqForm />} />

      <Route path="instructions" element={<Instructions />} />
    </Routes>
  );
}

PagesRoute.propTypes = {
  collapsed: PropTypes.bool,
};

export default PagesRoute;
