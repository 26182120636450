/* eslint-disable import/no-cycle */
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import rootSaga from './sagas/rootSaga';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const initializeStore = () => {
  const reduxStore = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
  const persistor = persistStore(reduxStore);
  return { reduxStore, persistor };
};

const store = initializeStore();
sagaMiddleware.run(rootSaga);

export default store;
