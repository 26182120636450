export const GET_ALL_POLICY = 'GET_ALL_POLICY';
export const SET_ALL_POLICY = 'SET_ALL_POLICY';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const GET_POLICY_ERROR = 'GET_POLICY_ERROR';
export const GET_EXPORT_POLICY_DATA = 'GET_EXPORT_POLICY_DATA';
export const SET_EXPORT_POLICY_DATA = 'SET_EXPORT_POLICY_DATA';
export const SET_EXPORT_POLICY_DATA_ERROR = 'SET_EXPORT_POLICY_DATA_ERROR';
export const CLEAR_POLICYPDF_DATA = 'CLEAR_POLICYPDF_DATA';
export const ADD_POLICY_MEMBER = 'ADD_POLICY_MEMBER';
export const ADD_POLICY_MEMBER_SUCCESS = 'ADD_POLICY_MEMBER_SUCCESS';
export const ADD_POLICY_MEMBER_ERROR = 'ADD_POLICY_MEMBER_ERROR';
export const GET_TPA_LISTS = 'GET_TPA_LISTS';
export const SET_TPA_LISTS = 'SET_TPA_LISTS';
export const GET_INSURANCE_PROVODERS = 'GET_INSURANCE_PROVODERS';
export const SET_INSURANCE_PROVODERS = 'SET_INSURANCE_PROVODERS';
export const ADD_POLICY = 'ADD_POLICY';
export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS';
export const ADD_POLICY_ERROR = 'ADD_POLICY_ERROR';
export const VIEW_POLICY = 'VIEW_POLICY';
export const SET_POLICY_BYID_SUCCESS = 'SET_POLICY_BYID_SUCCESS';
export const SET_POLICY_BYID_ERROR = 'SET_POLICY_BYID_ERROR';
export const NETWORK_HOSPITAL = 'NETWORK_HOSPITAL';
export const GET_HOSPITAL_LIST_ERROR = 'GET_HOSPITAL_LIST_ERROR';
export const GET_HOSPITAL_LIST_SUCCESS = 'GET_HOSPITAL_LIST_SUCCESS';
export const DOWNLOAD_POLICY_DOC = 'DOWNLOAD_POLICY_DOC';
export const DOWNLOAD_POLICY_DOC_SUCCESS = 'DOWNLOAD_POLICY_DOC_SUCCESS';
export const DOWNLOAD_POLICY_DOC_ERROR = 'DOWNLOAD_POLICY_DOC_ERROR';
export const CLEAR_POLICY_DOWNLOAD_STATE = 'CLEAR_POLICY_DOWNLOAD_STATE';
export const SAMPLE_ADD_MEMBER = 'SAMPLE_ADD_MEMBER';
export const SAMPLE_ADD_MEMBER_SUCCESS = 'SAMPLE_ADD_MEMBER_SUCCESS';
export const RESET_MEMEBR_SAMPLE_PDF = 'RESET_MEMEBR_SAMPLE_PDF';
export const VIEW_MEMBER_LIST = 'VIEW_MEMBER_LIST';
export const SET_MEMBER_LIST_SUCCESS = 'SET_MEMBER_LIST_SUCCESS';
export const ADD_MIS = 'ADD_MIS';
export const ADD_MIS_SUCCESS = 'ADD_MIS_SUCCESS';
export const ADD_MIS_ERROR = 'ADD_MIS_ERROR';
export const RESET_ADDMIS = 'RESET_ADDMIS';
export const RESET_MEMBER_LIST = 'RESET_MEMBER_LIST';
export const EMPLOYEE_E_CARD_DOWNLOAD = 'EMPLOYEE_E_CARD_DOWNLOAD';
export const HR_E_CARD_DOWNLOAD = 'HR_E_CARD_DOWNLOAD';
export const SET_HR_E_CARD = 'SET_HR_E_CARD';
export const SET_POLICY_MEMBER_LIST = 'SET_POLICY_MEMBER_LIST';
export const GET_POLICY_MEMBER_LIST = 'GET_POLICY_MEMBER_LIST';
export const ADD_SOS = 'ADD_SOS';
export const SOS_SUCCESS = 'SOS_SUCCESS';
export const GET_EXPORT_MIS = 'GET_EXPORT_MIS';
export const GET_CD_NUMBER = 'GET_CD_NUMBER';
