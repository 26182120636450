import * as types from './actionTypes';

export const getClaims = (data) => {
  return {
    type: types.GET_ALL_CLAIMS,
    payload: data,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const setAllClaims = (data) => {
  return {
    type: types.SET_ALL_CLAIMS,
    data,
  };
};

export const getExportsClaims = (data) => {
  return {
    type: types.GET_EXPORT_CLAIMS,
    payload: data,
  };
};
export const getStatus = (payload) => {
  return {
    type: types.GET_ALL_STATUS,
    payload,
  };
};
export const setStatus = (data) => {
  return {
    type: types.SET_STATUS,
    payload: data,
  };
};

export const updateStatus = (data) => {
  return {
    type: types.UPDATE_STATUS,
    payload: data,
  };
};
export const setSingleStatus = (data) => {
  return {
    type: types.SET_SINGLE_STATUS,
    payload: data,
  };
};
export const updateStatusSuccess = (data) => {
  return {
    type: types.UPDATE_STATUS_SUCCESS,
    payload: data,
  };
};
export const getEmployee = (data) => {
  return {
    type: types.GET_EMPLOYEE,
    payload: data,
  };
};
export const getEmployeeSuperAdmin = (data) => {
  return {
    type: types.GET_EMPLOYEE_SUPER_ADMIN,
    payload: data,
  };
};
export const setEmployee = (data) => {
  return {
    type: types.SET_EMPLOYEE,
    payload: data,
  };
};
export const getPatient = (data) => {
  return {
    type: types.GET_PATIENT,
    payload: data,
  };
};
export const setPatient = (data) => {
  return {
    type: types.SET_PATIENT,
    payload: data,
  };
};
export const setClaimType = (data) => {
  return {
    type: types.SET_CLAIM_TYPE,
    payload: data,
  };
};
export const getInstructions = (data) => {
  return {
    type: types.GET_INSTRUCTIONS,
    payload: data,
  };
};
export const setInstructions = (data) => {
  return {
    type: types.SET_INSTRUCTIONS,
    payload: data,
  };
};
export const raiseClaim = (data) => {
  return {
    type: types.RAISE_CLAIM,
    payload: data,
  };
};
export const raiseCashlessClaim = (data) => {
  return {
    type: types.RAISE_CASHLESS_CLAIM,
    payload: data,
  };
};

export const raiseClaimSuccess = (data) => {
  return {
    type: types.RAISE_CLAIM_SUCCESS,
    payload: data,
  };
};
export const raiseClaimSuccessSecond = (data) => {
  return {
    type: types.RAISE_CLAIM_SUCCESS_SECOND,
    payload: data,
  };
};
export const raiseCashlessClaimSuccessSecond = (data) => {
  return {
    type: types.RAISE_CLAIM_SUCCESS_SECOND,
    payload: data,
  };
};

export const addClaimError = (data) => {
  return {
    type: types.ADD_CLAIM_ERROR,
    payload: data,
  };
};
export const updateInstructions = (data) => {
  return {
    type: types.UPDATE_INSTUCTIONS,
    payload: data,
  };
};
export const updateInstructionsSuccess = (data) => {
  return {
    type: types.UPDATE_INSTUCTIONS_SUCCESS,
    payload: data,
  };
};

export const getClaimDocuments = (data) => {
  return {
    type: types.GET_CLAIMS_DOCUMENTS,
    payload: data,
  };
};
export const setClaimDocuments = (data) => {
  return {
    type: types.SET_CLAIMS_DOCUMENTS,
    payload: data,
  };
};
export const uploadClaimDocument = (data) => {
  return {
    type: types.UPLOAD_CLAIMS_DOCUMENTS,
    payload: data,
  };
};
export const uploadClaimDocumentsSuccess = (data) => {
  return {
    type: types.UPLOAD_CLAIMS_DOCUMENTS_SUCCESS,
    payload: data,
  };
};

export const claimZipFile = (data) => {
  return {
    type: types.CLAIM_ZIP_FILE,
    payload: data,
  };
};
