export const GET_ALL_RFQ = 'GET_ALL_RFQ';
export const SET_ALL_RFQ = 'SET_ALL_RFQ';
export const GET_POLICY_LIST = 'GET_POLICY_LIST';
export const SET_POLICY_LIST = 'SET_POLICY_LIST';
export const GET_POLICY_DETAIL = 'GET_POLICY_DETAIL';
export const SET_POLICY_DETAIL = 'SET_POLICY_DETAIL';
export const ADD_RFQ = 'ADD_RFQ';
export const SET_RFQ_SUCCESS = 'SET_RFQ_SUCCESS';
export const UPLOAD_RFQ_FILE = 'UPLOAD_RFQ_FILE';
export const SET_UPLOAD_RFQ_FILE_SUCCESS = 'SET_UPLOAD_RFQ_FILE_SUCCESS';
export const GET_RFQ_KPI = 'GET_RFQ_KPI';
export const SET_RFQ_KPI = 'SET_RFQ_KPI';
export const GET_RFQ_BY_ID = 'GET_RFQ_BY_ID';
export const SET_RFQ_BY_ID = 'SET_RFQ_BY_ID';
export const UPDATE_RFQ = 'UPDATE_RFQ';
export const SET_UPDATE_RFQ_SUCCESS = 'SET_UPDATE_RFQ_SUCCESS';
export const GET_EXPORT_EXCEL = 'GET_EXPORT_EXCEL';
export const GET_EXPORT_LOG = 'GET_EXPORT_LOG';
export const SET_EXPORT_LOG = 'SET_EXPORT_LOG';

export const SET_META_PARAMS = 'SET_META_PARAMS';
// export const SET_SPECIFIC_POLICYLIST = 'SET_SPECIFIC_POLICYLIST';
// export const GET_SPECIFIC_POLICYLIST = 'GET_SPECIFIC_POLICYLIST';
