/* eslint-disable import/no-cycle */
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getExportExcelApi,
  getExportLogApi,
  getPolicyDetailApi,
  getPolicyListApi,
  getRfqApi,
  getRfqIdApi,
  getRfqKpiApi,
  rfqApi,
  updateRfqApi,
  uploadRfqApi,
} from '../requests/rfqRequests';
import * as types from '../../rfq/actionTypes';

import {
  setAllRfq,
  setExportLog,
  setMetaParams,
  setPolicyDetail,
  setPolicyList,
  setRfqById,
  setRfqKpi,
  setRfqSuccess,
  setUpdateRfqSuccess,
  setUploadRfqFileSuccess,
} from '../../rfq/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';

function* getRfqHandler(values) {
  yield delay(500);
  yield startLoader('transparent');
  try {
    const { data } = yield call(getRfqApi, values.payload);
    if (data?.status) {
      yield put(setAllRfq(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getPolicyListHandler(values) {
  yield delay(500);
  yield startLoader('transparent');
  try {
    const { data } = yield call(getPolicyListApi, values.payload);
    if (data?.status) {
      yield put(setPolicyList(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getPolicyDetailHandler(values) {
  yield delay(500);
  yield startLoader('transparent');
  try {
    const { data } = yield call(getPolicyDetailApi, values.payload);
    if (data?.status) {
      yield put(setPolicyDetail(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* addRfqHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(rfqApi, values.payload);
    if (data?.status) {
      yield put(setPolicyDetail(data.data));
      yield put(setRfqSuccess(true));

      yield put(setMetaParams(data.meta_params));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* uploadRfqHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(uploadRfqApi, values.payload);
    if (data?.status) {
      yield put(setUploadRfqFileSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getRfqKpiHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getRfqKpiApi, values.payload);
    if (data?.status) {
      yield put(setRfqKpi(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getRfqIdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getRfqIdApi, values.payload);
    if (data?.status) {
      yield put(setRfqById(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateRfqIdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateRfqApi, values.payload);
    if (data?.status) {
      yield put(setUpdateRfqSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getExportExcelHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getExportExcelApi, values.payload);
    if (data?.status) {
      window.open(data.data?.link, '_blank');
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getExportLogHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getExportLogApi, values.payload);
    if (data?.status) {
      yield put(setExportLog(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
export default function* rfqSaga() {
  yield takeLatest(types.GET_ALL_RFQ, getRfqHandler);
  yield takeLatest(types.GET_POLICY_LIST, getPolicyListHandler);
  yield takeLatest(types.GET_POLICY_DETAIL, getPolicyDetailHandler);
  yield takeLatest(types.ADD_RFQ, addRfqHandler);
  yield takeLatest(types.UPLOAD_RFQ_FILE, uploadRfqHandler);
  yield takeLatest(types.GET_RFQ_KPI, getRfqKpiHandler);
  yield takeLatest(types.GET_RFQ_BY_ID, getRfqIdHandler);
  yield takeLatest(types.UPDATE_RFQ, updateRfqIdHandler);
  yield takeLatest(types.GET_EXPORT_EXCEL, getExportExcelHandler);
  yield takeLatest(types.GET_EXPORT_LOG, getExportLogHandler);
}
