export const SET_SEARCH_LOADER = 'SET_SEARCH_LOADER';
export const SET_PAGE_LOADER = 'SET_PAGE_LOADER';
export const SET_LIST_LOADER = 'SET_LIST_LOADER';
export const SET_TRANSPARENT_LOADER = 'SET_TRANSPARENT_LOADER';
export const SET_IMAGE_LOADER = 'SET_IMAGE_LOADER';

export const setSearchLoader = (payload) => {
  return {
    type: SET_SEARCH_LOADER,
    payload,
  };
};

export const setPageLoader = (payload) => {
  return {
    type: SET_PAGE_LOADER,
    payload,
  };
};

export const setTransparentLoader = (payload) => {
  return {
    type: SET_TRANSPARENT_LOADER,
    payload,
  };
};

export const setListLoader = (payload) => {
  return {
    type: SET_LIST_LOADER,
    payload,
  };
};

export const setImageLoader = (payload) => {
  return {
    type: SET_IMAGE_LOADER,
    payload,
  };
};
