/* eslint-disable import/no-cycle */
import { call, put, delay, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../hr/actionTypes';

import {
  addHRApi,
  getAllHRApi,
  resetPasswordApi,
  deleteHRApi,
  hrPermissionApi,
  updateHRApi,
} from '../requests/hrRequests';
import {
  addHRSuccess,
  addHRError,
  setMetaParams,
  resetPasswordError,
  deleteHRError,
  deleteHRSuccess,
  setAllHR,
  updateHRSuccess,
  updateHRError,
  setHRPermissionSuccess,
  setHRPermissionError,
} from '../../hr/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';

function* addHRHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addHRApi, values?.payload);
    if (data?.status) {
      // yield put(addHRError(data.meta_params));
      yield put(addHRSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(addHRError(data.meta_params));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(addHRError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* updateHRHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(updateHRApi, values?.payload);
    if (data?.status) {
      yield put(updateHRSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(addHRError(data.meta_params));

      yield put(updateHRError(data.message));
    }
  } catch (err) {
    yield put(updateHRError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getHRHandler(values) {
  yield delay(500);
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllHRApi, values);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllHR(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addHRError(data.message));
    }
  } catch (err) {
    yield put(addHRError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getResetPasswordHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(resetPasswordApi, values?.payload);
    if (data?.status) {
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(resetPasswordError(data.message));
    }
  } catch (err) {
    yield put(resetPasswordError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* deleteHRHandler(values) {
  yield delay(200);
  try {
    const { data } = yield call(deleteHRApi, values?.payload);
    if (data?.status) {
      yield put(deleteHRSuccess(values.payload));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(deleteHRError(data.message));
    }
  } catch (err) {
    yield put(deleteHRError(err));
  }
}
function* getHRPermissionHandler(values) {
  yield delay(200);
  try {
    const { data } = yield call(hrPermissionApi, values?.payload);
    if (data?.status) {
      yield put(setHRPermissionSuccess(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setHRPermissionError(data.message));
    }
  } catch (err) {
    yield put(deleteHRError(err));
  }
}

export default function* hrSaga() {
  yield takeLatest(types.ADD_HR, addHRHandler);
  yield takeLatest(types.GET_ALL_HR, getHRHandler);
  yield takeLatest(types.RESET_PASSWORD_HR, getResetPasswordHandler);
  yield takeLatest(types.DELETE_HR, deleteHRHandler);
  yield takeLatest(types.GET_HR_PERMISSION, getHRPermissionHandler);
  yield takeLatest(types.UPDATE_HR, updateHRHandler);
}
