/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
// import { getQueryString } from '../../../utility/utility';

export const addHRApi = (values) => {
  const payload = {
    name: values.name,
    email: values.email,
    permissionId: values.permissions,
  };
  return DataService.post(`/organizations/${values?.orgId}/human-resources`, payload);
};
export const updateHRApi = (values) => {
  const payload = {
    name: values.name,
    email: values.email,
    permissionId: values.permissionId,
  };
  return DataService.put(`/human-resources/${values.id}`, payload);
};
export const getAllHRApi = (values) => {
  const id = values?.payload?.OrgId;
  const payload = values.payload.search ? values.payload.search : '';
  const page = values.payload.page ? values.payload.page : 1;
  return DataService.get(`organizations/${id}/human-resources?page=${page}&search=${payload}`);
};
export const resetPasswordApi = (values) => {
  return DataService.put(`human-resources/${values.orgId}/reset-password`);
};
export const deleteHRApi = (values) => {
  return DataService.delete(`/human-resources/${values}`);
};
export const hrPermissionApi = () => {
  return DataService.get(`/organizations/human-resources/permission`);
};
