import * as types from './actionTypes';

const initialState = {
  loading: false,
  metaParams: {},
  allRfq: [],
  policyList: [],
  policyDetail: {},
  addRfqSuccess: false,
  editRfqData: {},
  uploadRfqFileSuccess: false,
  updateRfqSuccess: false,
  logExportData: [],
  kpiData: {},
  // SpecificPolicyList: [],
};

const rfqReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.SET_ALL_RFQ:
      return {
        ...state,
        allRfq: action.payload,
      };
    case types.SET_POLICY_LIST:
      return {
        ...state,
        policyList: action.payload,
      };
    case types.SET_POLICY_DETAIL:
      return {
        ...state,
        policyDetail: action.payload,
      };
    case types.SET_RFQ_SUCCESS:
      return {
        ...state,
        addRfqSuccess: action.payload,
      };
    case types.SET_UPLOAD_RFQ_FILE_SUCCESS:
      return {
        ...state,
        uploadRfqFileSuccess: action.payload,
      };
    case types.SET_RFQ_BY_ID:
      return {
        ...state,
        editRfqData: action.payload,
      };

    case types.SET_UPDATE_RFQ_SUCCESS:
      return {
        ...state,
        updateRfqSuccess: action.payload,
      };
    case types.SET_EXPORT_LOG:
      return {
        ...state,
        logExportData: action.payload,
      };
    case types.SET_RFQ_KPI:
      return {
        ...state,
        kpiData: action.payload,
      };

    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    // case types.SET_SPECIFIC_POLICYLIST:
    //   return {
    //     ...state,
    //     policyList: action.payload,
    //   };

    default:
      return state;
  }
};

export default rfqReducer;
