/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { getQueryString } from '../../../utility/utility';

export const addTpaApi = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('fullAddress', values.address);
  formData.append('logo', values.logo);
  formData.append('hospitalUrl', values.hospitalList);
  return DataService.post('/tpas', formData);
};

export const getAllTpasApi = () => {
  return DataService.get('/tpas');
};

export const searchTpaApi = (values) => {
  return DataService.get(`/tpas?${getQueryString(values)}`);
};

export const deleteTpaApi = (values) => {
  return DataService.delete(`/tpas/${values}`);
};

export const updateTpaApi = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('fullAddress', values.address);
  if (values.logo) {
    formData.append('logo', values.logo);
  }
  formData.append('hospitalUrl', values.hospitalList);
  formData.append('_method', 'PUT');
  return DataService.post(`/tpas/${values.id}`, formData);
};

export const tpaActiveApi = (values) => {
  return DataService.put(`/tpas/${values.id}/update-status`, values);
};

export const getNextPageTpaApi = (values) => {
  return DataService.get(`/tpas?${getQueryString(values)}`);
};
