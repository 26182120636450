/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';

export const getRfqApi = (value) => {
  return DataService.get(`/rfq?page=${value.page}&search=${value.search}`);
};
export const getPolicyListApi = (value) => {
  return DataService.get(`/rfq/policies?search=${value.search}`);
};
export const getPolicyDetailApi = (id) => {
  return DataService.get(`/rfq/policy/${id}`);
};
export const rfqApi = (values) => {
  return DataService.post(`/rfq`, values);
};
export const uploadRfqApi = (values) => {
  const formData = new FormData();
  formData.append('rfqId', values.id);
  formData.append('file', values.fileData);
  return DataService.post(`/rfq/upload/file`, formData);
};

export const getRfqKpiApi = (data) => {
  return DataService.get(`rfq/kpis?type=${data.type}&value=${data.value}`);
};

export const getRfqIdApi = (id) => {
  return DataService.get(`rfq/${id}`);
};

export const updateRfqApi = (data) => {
  return DataService.put(`rfq/${data.rfqId}`, data);
};

export const getExportExcelApi = (id) => {
  return DataService.get(`rfq/export/${id}`);
};
export const getExportLogApi = (id) => {
  return DataService.get(`rfq/${id}/logs?page=1`);
};
