import * as types from './actionTypes';

export const getAllEndorsement = (data) => {
  return {
    type: types.GET_ALL_ENDORSEMENT,
    payload: data,
  };
};

// export const getAllAdminEndorsement = (data) => {
//   return {
//     type: types.GET_ALL_ADMIN_ENDORSEMENT,
//     payload: data,
//   };
// };

export const setAllEndorsement = (data) => {
  return {
    type: types.SET_ALL_ENDORSEMENT,
    payload: data,
  };
};

// export const setAllAdminEndorsement = (data) => {
//   return {
//     type: types.SET_ALL_ENDORSEMENT,
//     payload: data,
//   };
// };

export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};
export const deletionEndorsement = (data) => {
  return {
    type: types.DELETION_ENDORSEMENT,
    payload: data,
  };
};

export const setEndorsementSuccess = (data) => {
  return {
    type: types.SET_ENDORSEMENT_SUCCESS,
    payload: data,
  };
};

export const getRelationEndorsement = (data) => {
  return {
    type: types.GET_RELATION_ENDORSEMENT,
    payload: data,
  };
};
export const setRelationData = (data) => {
  return {
    type: types.SET_RELATION_DATA,
    payload: data,
  };
};

export const addEndorsement = (data) => {
  return {
    type: types.ADD_ENDORSEMENT,
    payload: data,
  };
};

export const correctionEndorsement = (data) => {
  return {
    type: types.CORRECTION_ENDORSEMENT,
    payload: data,
  };
};

export const getExportsEndorsement = (data) => {
  return {
    type: types.GET_EXPORTS_ENDORSEMENT,
    payload: data,
  };
};

export const getAdminExportsEndorsement = (data) => {
  return {
    type: types.GET_ADMIN_EXPORTS_ENDORSEMENT,
    payload: data,
  };
};

export const statusUpdateEndorsement = (data) => {
  return {
    type: types.STATUS_UPDATE_ENDORSEMENT,
    payload: data,
  };
};

export const newStatusUpdateEndorsement = (data) => {
  return {
    type: types.NEW_STATUS_UPDATE_ENDORSEMENT,
    payload: data,
  };
};

export const addSelfEndorsementByHr = (data) => {
  return {
    type: types.ADD_SELF_ENDORSEMENT_BY_HR,
    payload: data,
  };
};

export const getSelfEndorsementListByHr = (data) => {
  return {
    type: types.GET_SELF_ENDORSEMENT_LIST_BY_HR,
    payload: data,
  };
};

export const setSelfEmployeeList = (data) => {
  return {
    type: types.SELF_EMPLOYEE_LIST,
    payload: data,
  };
};

export const deleteSelfEndorsementByHr = (data) => {
  return {
    type: types.DELETE_SELF_ENDORSEMENT_BY_HR,
    payload: data,
  };
};

export const setDeleteEndorsementSuccess = (data) => {
  return {
    type: types.SET_DELETE_ENDORSEMENT_SUCCESS,
    payload: data,
  };
};

export const sendToRtf = (data) => {
  return {
    type: types.SEND_TO_RTF,
    payload: data,
  };
};

export const setDeleteMetaParams = (data) => {
  return {
    type: types.SET_DELETE_META_PARAMS,
    payload: data,
  };
};
