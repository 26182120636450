export const ADD_TPA = 'ADD_TPA';
export const ADD_TPA_SUCCESS = 'ADD_TPA_SUCCESS';
export const ADD_TPA_ERROR = 'ADD_TPA_ERROR';
export const ADD_TPA_RESET = 'ADD_TPA_RESET';
export const GET_ALL_TPA = 'GET_ALL_TPA';
export const SET_ALL_TPA = 'SET_ALL_TPA';
export const SEARCH_TPA = 'SEARCH_TPA';
export const DELETE_TPA = 'DELETE_TPA';
export const DELETE_TPA_SUCCESS = 'DELETE_TPA_SUCCESS';
export const DELETE_TPA_ERROR = 'DELETE_TPA_ERROR';
export const UPDATE_TPA = 'UPDATE_TPA';
export const UPDATE_TPA_SUCCESS = 'UPDATE_TPA_SUCCESS';
export const UPDATE_TPA_ERROR = 'UPDATE_TPA_ERROR';
export const UPDATE_TPA_RESET = 'UPDATE_TPA_RESET';
export const ACTIVE_TPA_STATUS = 'ACTIVE_TPA_STATUS';
export const ACTIVE_TPA_STATUS_SUCCESS = 'ACTIVE_TPA_STATUS_SUCCESS';
export const ACTIVE_TPA_STATUS_ERROR = 'ACTIVE_TPA_STATUS_ERROR';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const NEXT_PAGE_TPA = 'NEXT_PAGE_TPA';
