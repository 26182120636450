/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Button, Col, Layout, Row } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import propTypes from 'prop-types';
import { Component } from 'react';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MenueItems from './MenueItems';

import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
import TopMenu from './TopMenu';
import logoImage from '../static/img/rtfLogo.svg';
import Search from '../components/utilities/auth-info/Search';
import AuthInfo from '../components/utilities/auth-info/info';
import { UsersRole } from '../utility/utility';

const { theme } = require('../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: true,
        hide: true,
        searchHide: true,
        customizerAction: false,
        activeSearch: false,
        userData: {},
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();

      const { auth } = this.props;
      if (auth) {
        this.setState({ userData: auth.user });
      }
    }

    componentDidUpdate(prevProps) {
      const { auth } = this.props;
      if (prevProps.auth !== auth && auth) {
        this.setState({ userData: auth.user });
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    setCollapse = (value) => {
      this.setState({ collapsed: value });
    };

    // default active toggle
    updateDimensions() {
      this.setState((prevState) => ({
        collapsed: window.innerWidth <= 1200 ? true : prevState.collapsed,
      }));
    }

    // updateDimensions() {
    //   this.setState({
    //     collapsed: window.innerWidth <= 1200 && true,
    //   });
    // }

    render() {
      const { userData } = this.state;
      const { collapsed, hide } = this.state;
      const { layoutMode, rtl, topMenu } = this.props;

      const hrRole = userData?.role === UsersRole.PRIMARY_HR || userData?.role === UsersRole.SECONDARY_HR;
      const left = !rtl ? 'left' : 'right';
      // const toggleCollapsed = () => {
      //   this.setState({
      //     collapsed: !collapsed,
      //   });
      // };

      const toggleCollapsed = () => {
        this.setState((prevState) => ({
          collapsed: !prevState.collapsed,
        }));
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState((prevState) => ({
            collapsed: !prevState.collapsed,
          }));
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };
      const SideBarStyle = {
        margin: hrRole ? '0px' : '63px 0px 0px',
        padding: `${!rtl ? '20px 20px 55px 0' : '20px 0 55px 20px'}`,
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        // color: hrRole ? 'rgba(75, 73, 172, 1)': ,
        zIndex: hrRole ? 1000 : 988,
      };

      const renderView = ({ style }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const renderThumbHorizontal = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };
      const innerElementStyle = {
        position: 'relative',
        top: '20px',
        left: '20px',
      };
      // const contentStyle = {
      //   marginLeft: '20px',
      //   padding: '0px',
      // };
      return (
        <LayoutContainer>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <div className="ninjadash-header-content d-flex">
                <div className="ninjadash-header-content__left">
                  {userData?.role === UsersRole.PRIMARY_HR || userData?.role === UsersRole.SECONDARY_HR ? (
                    <>
                      {!topMenu || window.innerWidth <= 991 ? (
                        <Button type="link" onClick={toggleCollapsed} style={{ paddingBottom: '29px' }}>
                          <img
                            src={require(`../static/img/icon/${collapsed ? 'left-bar.svg' : 'left-bar.svg'}`)}
                            alt="menu"
                          />
                        </Button>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                  {userData?.role === UsersRole.SUPER_ADMIN ||
                  userData?.role === UsersRole.EMPLOYEE ||
                  userData?.role === UsersRole.CLAIM_CREATOR ||
                  userData?.role === UsersRole.POLICY_CREATOR ||
                  userData?.role === UsersRole.ENDORSEMENT_CREATOR ? (
                    <div className="navbar-brand align-cener-v">
                      <p className={topMenu && window.innerWidth > 991 ? 'ninjadash-logo top-menu' : 'ninjadash-logo'}>
                        <img src={logoImage} alt="" />
                      </p>
                      {!topMenu || window.innerWidth <= 991 ? (
                        <Button type="link" onClick={toggleCollapsed} style={{ paddingBottom: '29px' }}>
                          <img
                            src={require(`../static/img/icon/${collapsed ? 'left-bar.svg' : 'left-bar.svg'}`)}
                            alt="menu"
                          />
                        </Button>
                      ) : null}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="ninjadash-header-content__right d-flex">
                  {userData?.role === UsersRole.PRIMARY_HR || userData?.role === UsersRole.SECONDARY_HR ? (
                    <div style={{ SideBarStyle }}>
                      <div style={{ innerElementStyle }}>
                        <div
                          style={{
                            marginLeft:
                              window.innerWidth > 1500
                                ? collapsed
                                  ? '-170px'
                                  : '-30px'
                                : collapsed
                                ? '-120px'
                                : '20px',
                          }}
                          className="hr-header-content"
                        >
                          <h2>
                            {' '}
                            <span className="hr-welcome">Welcome,</span>{' '}
                            <span className="hr-username">{userData?.name}</span>
                          </h2>
                          <p className="hr-organization">{userData?.organization?.companyName}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="ninjadash-navbar-menu d-flex align-center-v">
                    {topMenu && window.innerWidth > 991 ? <TopMenu /> : null}
                  </div>
                  {userData?.role === UsersRole.SUPER_ADMIN ||
                  userData?.role === UsersRole.EMPLOYEE ||
                  userData?.role === UsersRole.CLAIM_CREATOR ||
                  userData?.role === UsersRole.POLICY_CREATOR ||
                  userData?.role === UsersRole.ENDORSEMENT_CREATOR ? (
                    <div className="ninjadash-nav-actions">
                      {topMenu && window.innerWidth > 991 ? (
                        <TopMenuSearch>
                          <div className="top-right-wrap d-flex">
                            <span />
                            <AuthInfo />
                          </div>
                        </TopMenuSearch>
                      ) : (
                        <AuthInfo />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="ninjadash-header-content__mobile">
                  <div className="ninjadash-mobile-action" style={{ display: hrRole ? 'none' : 'block' }}>
                    <div className="btn-search" to="#">
                      <Search />
                    </div>

                    <Link className="btn-auth" onClick={onShowHide} to="#">
                      <UilEllipsisV />
                    </Link>
                  </div>
                </div>
              </div>
            </Header>
            <div className="ninjadash-header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="ninjadash-header-more-inner">
                    <SmallScreenAuthInfo hide={hide}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={theme}>
                  <Sider
                    width={hrRole ? 220 : 280}
                    style={{
                      ...SideBarStyle,
                      minWidth: collapsed ? 'auto' : '20px',
                      paddingBottom: hrRole ? '0px' : '55px',
                      overflowY: hrRole ? 'visible' : 'auto',
                    }}
                    collapsed={collapsed}
                    theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                    className={hrRole ? 'hr-sider-aside' : ''}
                  >
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={(props) => <div {...props} className="ninjadash-track-vertical" />}
                    >
                      <MenueItems
                        topMenu={topMenu}
                        toggleCollapsedMobile={toggleCollapsedMobile}
                        toggleCollapsed={toggleCollapsed}
                        setCollapse={this.setCollapse}
                        collapsed={collapsed}
                      />
                    </Scrollbars>
                    {userData?.role === UsersRole.PRIMARY_HR || userData?.role === UsersRole.SECONDARY_HR ? (
                      <>
                        <ButtonGroup className="sider-toggle-button" onClick={toggleCollapsed}>
                          {collapsed ? (
                            <FaAngleRight size={15} className="sider-toggle-icon angle-right" />
                          ) : (
                            <FaAngleLeft size={15} className="sider-toggle-icon" />
                          )}
                        </ButtonGroup>

                        <Button
                          type="link"
                          className="sider-mob-button"
                          onClick={toggleCollapsed}
                          style={{ paddingBottom: '29px' }}
                        >
                          <img
                            src={require(`../static/img/icon/${collapsed ? 'left-bar.svg' : 'left-bar.svg'}`)}
                            alt="menu"
                          />
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className={`atbd-main-layout ${hrRole ? 'hr-layout-dash' : ''}`}>
                <Content>
                  <WrappedComponent {...this.props} collapsed={collapsed} />
                  <FooterStyle justify="space-between" className="admin_footer">
                    <Row>
                      <Col md={12} xs={24}>
                        <div className="admin-license">IRDA License No: 791 | License valid till: 23.12.2024</div>
                      </Col>

                      <Col md={12} xs={24}>
                        <div className="admin-copyright">
                          Copyright © 2024 RTF Insurance Brokers Pvt Ltd. All Rights Reserved.
                        </div>
                      </Col>
                    </Row>
                  </FooterStyle>
                </Content>
              </Layout>
            </Layout>
          </Layout>
          {window.innerWidth <= 991 ? (
            <span className={collapsed ? 'ninjadash-shade' : 'ninjadash-shade show'} onClick={toggleCollapsed} />
          ) : (
            ''
          )}
        </LayoutContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  LayoutComponent.propTypes = {
    auth: propTypes.object,
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
