/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { getQueryString } from '../../../utility/utility';

export const addOrganizationApi = (values) => {
  const formData = new FormData();
  formData.append('companyName', values.name);
  formData.append('companyLogo', values.companyLogo);
  formData.append('panNumber', values.panNumber);
  formData.append('gstNumber', values.gstNumber);
  return DataService.post('/organizations', formData);
};
export const updateOrganizationApi = (values) => {
  const formData = new FormData();
  formData.append('companyName', values.companyName);
  formData.append('panNumber', values.panNumber);
  formData.append('gstNumber', values.gstNumber);
  if (values) {
    formData.append('companyLogo', values.companyLogo);
  }
  formData.append('_method', 'PUT');
  return DataService.post(`/organizations/${values.id}`, formData);
};
export const getAllOrganizationApi = () => {
  return DataService.get('/organizations');
};
export const searchOrganizationApi = (values) => {
  return DataService.get(`/organizations?${getQueryString(values)}`);
};
export const getOrganizationByIdApi = (values) => {
  const Id = values?.data?.id;
  return DataService.get(`/organizations/${Id}`);
};
export const getNextPageOrganizationApi = (values) => {
  return DataService.get(`/organizations?${getQueryString(values)}`);
};

export const getOrganizationListApi = (values) => {
  return DataService.get(`/organization/list?search=${values.data}`);
};
