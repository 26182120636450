import { Spin } from 'antd';
import React, { Suspense } from 'react';

import { AuthenticationWrap } from './overview/style';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap
          style={{
            // backgroundImage: `url("${require('../../../static/img/admin-bg-light.png')}")`,
            padding: '0',
          }}
        >
          <div className="ninjadash-authentication-wrap ">
            <WraperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
