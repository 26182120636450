export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const GET_ALL_ORGANIZATION = 'GET_ALL_ORGANIZATION';
export const SEARCH_ORGANIZATION = 'SEARCH_ORGANIZATION';
export const SET_ALL_ORGANIZATION = 'SET_ALL_ORGANIZATION';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const ADD_ORGANIZATION_ERROR = 'ADD_ORGANIZATION_ERROR';
export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID';
export const SET_ORGANIZATION_BY_ID = 'SET_ORGANIZATION_BY_ID';
export const GET_ORGANIZATION_BY_ID_ERROR = 'GET_ORGANIZATION_BY_ID_ERROR';
export const NEXT_PAGE_ORGANIZATION = 'NEXT_PAGE_ORGANIZATION';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const UPDATE_ORGANIZATION_RESET = 'UPDATE_ORGANIZATION_RESET';
export const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST';
export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST';
