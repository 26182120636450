import { delay, put } from 'redux-saga/effects';
import {
  SET_IMAGE_LOADER,
  SET_LIST_LOADER,
  SET_PAGE_LOADER,
  SET_TRANSPARENT_LOADER,
} from '../redux/loader/loaderAction';

export function* startLoader(type) {
  switch (type) {
    case 'page':
      yield put({ type: SET_PAGE_LOADER, payload: true });
      break;
    case 'list':
      yield put({ type: SET_LIST_LOADER, payload: true });
      break;
    case 'transparent':
      yield put({ type: SET_TRANSPARENT_LOADER, payload: true });
      break;
    case 'img_loader':
      yield put({ type: SET_IMAGE_LOADER, payload: true });
      break;
    default:
      break;
  }
}

export function* stopLoader(type) {
  switch (type) {
    case 'page':
      yield delay(1000);
      yield put({ type: SET_PAGE_LOADER, payload: false });
      break;
    case 'list':
      yield put({ type: SET_LIST_LOADER, payload: false });
      break;
    case 'transparent':
      yield put({ type: SET_TRANSPARENT_LOADER, payload: false });
      break;
    case 'img_loader':
      yield put({ type: SET_IMAGE_LOADER, payload: false });
      break;
    default:
      break;
  }
}
