import * as types from './actionTypes';

export const getAllRfq = (data) => {
  return {
    type: types.GET_ALL_RFQ,
    payload: data,
  };
};
export const setAllRfq = (data) => {
  return {
    type: types.SET_ALL_RFQ,
    payload: data,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const getPolicyList = (data) => {
  return {
    type: types.GET_POLICY_LIST,
    payload: data,
  };
};
export const setPolicyList = (data) => {
  return {
    type: types.SET_POLICY_LIST,
    payload: data,
  };
};

export const getPolicyDetail = (data) => {
  return {
    type: types.GET_POLICY_DETAIL,
    payload: data,
  };
};
export const setPolicyDetail = (data) => {
  return {
    type: types.SET_POLICY_DETAIL,
    payload: data,
  };
};
export const addRfq = (data) => {
  return {
    type: types.ADD_RFQ,
    payload: data,
  };
};
export const setRfqSuccess = (data) => {
  return {
    type: types.SET_RFQ_SUCCESS,
    payload: data,
  };
};
export const uploadRfqFile = (data) => {
  return {
    type: types.UPLOAD_RFQ_FILE,
    payload: data,
  };
};

export const setUploadRfqFileSuccess = (data) => {
  return {
    type: types.SET_UPLOAD_RFQ_FILE_SUCCESS,
    payload: data,
  };
};
export const getRfqKpi = (data) => {
  return {
    type: types.GET_RFQ_KPI,
    payload: data,
  };
};
export const setRfqKpi = (data) => {
  return {
    type: types.SET_RFQ_KPI,
    payload: data,
  };
};

export const getRfqById = (data) => {
  return {
    type: types.GET_RFQ_BY_ID,
    payload: data,
  };
};

export const setRfqById = (data) => {
  return {
    type: types.SET_RFQ_BY_ID,
    payload: data,
  };
};

export const updateRfq = (data) => {
  return {
    type: types.UPDATE_RFQ,
    payload: data,
  };
};

export const setUpdateRfqSuccess = (data) => {
  return {
    type: types.SET_UPDATE_RFQ_SUCCESS,
    payload: data,
  };
};

export const getExportExcel = (data) => {
  return {
    type: types.GET_EXPORT_EXCEL,
    payload: data,
  };
};

export const getExportLog = (data) => {
  return {
    type: types.GET_EXPORT_LOG,
    payload: data,
  };
};
export const setExportLog = (data) => {
  return {
    type: types.SET_EXPORT_LOG,
    payload: data,
  };
};
// export const getSpecificPolicyList = () => {
//   return {
//     type: types.GET_SPECIFIC_POLICYLIST,
//   };
// };
