// import { Form } from 'antd';
import React from 'react';
// import { useSelector } from 'react-redux';

const SearchBar = React.memo(() => {
  // const [form] = Form.useForm();

  // const [state, setState] = useState({
  //   openSearch: false,
  // });

  // const closeSearchbar = (e) => {
  //   e.preventDefault();
  //   setState({
  //     ...state,
  //     openSearch: false,
  //   });
  // };

  return (
    <div>
      {/* <div className="ninjadash-searchbar">
        <Form form={form} name="ninjadash-search">
          <Form.Item name="search-input">{/* <Input placeholder="Search Here" /> </Form.Item>
        </Form>
      </div> */}
      {/* 
      <Link to="/" onClick={(e) => closeSearchbar(e)} className="ninjadash-close-icon">
        <UilTimes />
      </Link> */}
    </div>
  );
});

export default SearchBar;
