import * as types from './actionTypes';

const initialState = {
  loading: false,
  metaParams: {},
  //   addCdSuccess: false,
  //   updateCdSuccess: false,
  endorsementList: [],
  relationList: [],
  selfEmployeeList: [],
  endorsementSuccess: false,
  selfEmployeeDeleteSuccess: false,
  // sendToRtfSUccess: false,
};

const endorsementReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.SET_ALL_ENDORSEMENT:
      return {
        ...state,
        endorsementList: action.payload,
      };

    case types.SET_ENDORSEMENT_SUCCESS:
      return {
        ...state,
        endorsementSuccess: action.payload,
      };

    case types.SET_RELATION_DATA:
      return {
        ...state,
        relationList: action.payload,
      };

    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.SELF_EMPLOYEE_LIST:
      return {
        ...state,
        selfEmployeeList: action.payload,
      };
    case types.SET_DELETE_ENDORSEMENT_SUCCESS:
      return {
        ...state,
        selfEmployeeDeleteSuccess: action.payload,
      };
    case types.SET_DELETE_META_PARAMS:
      return {
        ...state,
        metaParams: action.payload,
      };
    default:
      return state;
  }
};

export default endorsementReducer;
