import * as types from './actionTypes';

export const loginBegin = () => {
  return {
    type: types.LOGIN_BEGIN,
  };
};

export const loginSuccess = (data) => {
  return {
    type: types.LOGIN_SUCCESS,
    data,
  };
};

export const loginErr = (err) => {
  return {
    type: types.LOGIN_ERR,
    err,
  };
};

export const logoutBegin = () => {
  return {
    type: types.LOGOUT_BEGIN,
  };
};

export const logoutSuccess = (data) => {
  return {
    type: types.LOGOUT_SUCCESS,
    data,
  };
};

export const logoutErr = (err) => {
  return {
    type: types.LOGOUT_ERR,
    err,
  };
};

export const startLogin = (payload, callback) => {
  return {
    type: types.START_LOGIN,
    payload: {
      data: payload,
      callback,
    },
  };
};

export const startLogOut = () => {
  return {
    type: types.START_LOGOUT,
  };
};

export const register = (payload) => {
  return {
    type: types.REGISTER_USER,
    payload,
  };
};

export const setUser = (payload) => {
  return {
    type: types.SET_USER,
    payload,
  };
};

export const getDashboardKpi = (payload) => {
  return {
    type: types.GET_KPI,
    payload,
  };
};
export const setKpi = (payload) => {
  return {
    type: types.SET_KPI,
    payload,
  };
};

export const forgotPassword = (payload) => {
  return {
    type: types.FORGOT_PASSWORD,
    payload,
  };
};

export const forgotPasswordSuccess = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_SUCESS,
    payload,
  };
};

export const forgotPasswordError = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_ERROR,
    payload,
  };
};

export const emailVerified = (data) => {
  return {
    type: types.EMAIL_VERIFIED,
    data,
  };
};

export const otpVerified = (data) => {
  return {
    type: types.OTP_VERIFIED,
    data,
  };
};

export const changePassword = (data) => {
  return {
    type: types.CHANGE_PASSWORD,
    data,
  };
};

export const changePasswordSuccess = (data) => {
  return {
    type: types.CHANGE_PASSWORD_SUCESS,
    data,
  };
};

export const changePasswordError = (data) => {
  return {
    type: types.CHANGE_PASSWORD_ERROR,
    data,
  };
};
export const resetAllData = () => {
  return {
    type: types.RESET_ALL_DATA,
  };
};

export const employeeRegister = (payload) => {
  return {
    type: types.EMPLOYEE_REGISTER,
    payload,
  };
};

export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const registerRedirection = (data) => {
  return {
    type: types.REGISTER_REDIRECTION,
    payload: data,
  };
};

export const verifyOtp = (payload) => {
  return {
    type: types.VERIFY_OTP,
    payload,
  };
};

export const otpRedirection = (data) => {
  return {
    type: types.OTP_REDIRECTION,
    payload: data,
  };
};
export const isMpinCreated = (data) => {
  return {
    type: types.ISMPINCREATED_REDIRECTION,
    payload: data,
  };
};

export const createMpin = (payload) => {
  return {
    type: types.CREATE_MPIN,
    payload,
  };
};

export const createMpinRedirection = (data) => {
  return {
    type: types.CREATE_MPIN_REDIRECTION,
    payload: data,
  };
};

export const employeeLogin = (payload, callback) => {
  return {
    type: types.EMPLOYEE_LOGIN,
    payload: {
      data: payload,
      callback,
    },
  };
};

export const employeeLoginRedirection = (data) => {
  return {
    type: types.EMPLOYEE_LOGIN_REDIRECTION,
    payload: data,
  };
};

export const getExportPortfolio = () => {
  return {
    type: types.GET_EXPORT_PORTFOLIO,
  };
};

export const setActiveUrl = (data) => {
  return {
    type: types.SET_ACTIVE_URL,
    payload: data,
  };
};
