export const ADD_HR = 'ADD_HR';
export const GET_ALL_HR = 'GET_ALL_HR';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const SET_ALL_HR = 'SET_ALL_HR';
export const UPDATE_HR = 'UPDATE_HR';
export const UPDATE_HR_SUCCESS = 'UPDATE_HR_SUCCESS';
export const UPDATE_HR_ERROR = 'UPDATE_HR_ERROR';
export const ADD_HR_SUCCESS = 'ADD_HR_SUCCESS';
export const ADD_HR_ERROR = 'ADD_HR_ERROR';
export const SET_HR_ERROR = 'SET_HR_ERROR';
export const RESET_PASSWORD_HR = 'RESET_PASSWORD_HR';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const DELETE_HR = 'DELETE_HR';
export const DELETE_HR_SUCCESS = 'DELETE_HR_SUCCESS';
export const DELETE_HR_ERROR = 'DELETE_HR_ERROR';
export const GET_HR_PERMISSION = 'GET_HR_PERMISSION';
export const SET_HR_PERMISSION_SUCCESS = 'SET_HR_PERMISSION_SUCCESS';
export const SET_HR_PERMISSION_ERROR = 'SET_HR_PERMISSION_ERROR';
