export const GET_ALL_CLAIMS = 'GET_ALL_CLAIMS';
export const SET_ALL_CLAIMS = 'SET_ALL_CLAIMS';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const GET_EXPORT_CLAIMS = 'GET_EXPORT_CLAIMS';
export const GET_ALL_STATUS = 'GET_ALL_STATUS';
export const SET_STATUS = 'SET_STATUS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const SET_SINGLE_STATUS = 'SET_SINGLE_STATUS';

export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const GET_EMPLOYEE_SUPER_ADMIN = 'GET_EMPLOYEE_SUPER_ADMIN';

export const GET_PATIENT = 'GET_PATIENT';
export const SET_PATIENT = 'SET_PATIENT';
export const SET_CLAIM_TYPE = 'SET_CLAIM_TYPE';
export const GET_INSTRUCTIONS = 'GET_INSTRUCTIONS';
export const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS';
export const RAISE_CLAIM = 'RAISE_CLAIM';
export const RAISE_CASHLESS_CLAIM = 'RAISE_CASHLESS_CLAIM';
export const ADD_CLAIM_ERROR = 'ADD_CLAIM_ERROR';
export const RAISE_CLAIM_SUCCESS = 'RAISE_CLAIM_SUCCESS';
export const RAISE_CLAIM_SUCCESS_SECOND = 'RAISE_CLAIM_SUCCESS_SECOND';
export const UPDATE_INSTUCTIONS = 'UPDATE_INSTUCTIONS';
export const UPDATE_INSTUCTIONS_SUCCESS = 'UPDATE_INSTUCTIONS_SUCCESS';
export const GET_CLAIMS_DOCUMENTS = 'GET_CLAIMS_DOCUMENTS';
export const SET_CLAIMS_DOCUMENTS = 'SET_CLAIMS_DOCUMENTS';
export const UPLOAD_CLAIMS_DOCUMENTS = 'UPLOAD_CLAIMS_DOCUMENTS';
export const UPLOAD_CLAIMS_DOCUMENTS_SUCCESS = 'UPLOAD_CLAIMS_DOCUMENTS_SUCCESS';
export const CLAIM_ZIP_FILE = 'CLAIM_ZIP_FILE';
