/* eslint-disable no-case-declarations */
import * as types from './actionTypes';

const initState = {
  loading: false,
  allInsurance: [],
  created: false,
  updated: false,
  metaParams: {},
  errors: {},
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.ADD_INSURANCE:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_INSURANCE_SUCCESS:
      let addNewParams = {};
      if (state.metaParams.pagination.total_count) {
        addNewParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count + 1 };
      }
      return {
        ...state,
        loading: false,
        created: true,
        allInsurance: [data.data, ...state.allInsurance],
        metaParams: { pagination: { ...addNewParams } },
      };
    case types.ADD_INSURANCE_ERROR:
      return {
        ...state,
        loading: false,
        errors: data?.formErrors,
      };
    case types.GET_ALL_INSURANCE:
      return {
        ...state,
        loading: true,
      };
    case types.SET_ALL_INSURANCE:
      return {
        ...state,
        loading: false,
        allInsurance: data,
      };
    case types.SEARCH_INSURANCE:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_INSURANCE:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_INSURANCE_SUCCESS:
      let remainingData = '';
      let newParams = '';
      if (action.payload) {
        remainingData = state.allInsurance.filter((item) => item.id !== action.payload);
        if (state.metaParams.pagination.total_count) {
          newParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count - 1 };
        }
      }
      return {
        ...state,
        allInsurance: [...remainingData],
        loading: false,
        metaParams: { pagination: { ...newParams } },
      };
    case types.DELETE_INSURANCE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_INSURANCE_RESET:
      return {
        ...state,
        loading: false,
        created: false,
      };
    case types.UPDATE_INSURANCE_RESET:
      return {
        ...state,
        loading: false,
        updated: false,
      };
    case types.UPDATE_INSURANCE:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_INSURANCE_SUCCESS:
      let updatedData = '';
      if (action.payload) {
        updatedData = state.allInsurance.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allInsurance: [...updatedData],
        updated: true,
      };
    case types.UPDATE_INSURANCE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload?.formErrors,
      };
    case types.ACTIVE_INSURANCE_STATUS:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVE_INSURANCE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.ACTIVE_INSURANCE_STATUS_SUCCESS:
      let activeUpdatedData = '';
      if (action.payload) {
        activeUpdatedData = state.allInsurance.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allInsurance: [...activeUpdatedData],
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    default:
      return state;
  }
};
export default AuthReducer;
